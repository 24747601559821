import { Button, Col, Form, FormListFieldData, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { useWatch } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { ICostBillingInfo } from "../../Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types";
import { IBilling_information } from "../../InvoiceUmrah/Types/InvoiceUmrahTypes";
import { useGetAllAgentProfileQuery } from "../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints";
import { Fixed2 } from "../../../common/utils/common.utils";
import { SelectProduct } from "../../../components/common/FormItem/SelectCustomFeilds";
import {
  FormInputItem,
  NumberInput,
} from "../../../components/common/FormItem/FormItems";
import BillingIsDeleted from "../../Invoice(Visa)/Components/BillingIsDeleted";

type Props = {
  field: FormListFieldData;
  form: FormInstance<any>;
  billInitialValues?: ICostBillingInfo[];
  billing_information?: IBilling_information[];
  setProductId: number | undefined;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  index: number;
  conditionalCostVendor?: boolean;
  requireSellingPrice?: boolean;
  disableRemoveButtonIndex?: number;
  totalCost?: number;
};

const InvoiceOtherPackageBillingInfoForm = ({
  field,
  form,
  billing_information,
  setProductId,
  add,
  remove,
  index,
  conditionalCostVendor,
  disableRemoveButtonIndex,
  totalCost,
  requireSellingPrice,
}: Props) => {
  const [disabled, setDisabled] = useState(false);
  const params = useParams();
  const id = params.id || !!billing_information;

  const quantity = useWatch(
    ["billing_information", field.name, "billing_quantity"],
    form
  );
  const sellUnitPrice = useWatch(
    ["billing_information", field.name, "billing_unit_price"],
    form
  );
  const sellPrice = useWatch(
    ["billing_information", field.name, "billing_total_sales"],
    form
  );
  const costPrice = useWatch(
    ["billing_information", field.name, "billing_cost_price"],
    form
  );
  const netCostPrice = useWatch(
    ["billing_information", field.name, "billing_total_cost_price"],
    form
  );
  const is_deleted: 0 | 1 = useWatch(
    ["billing_information", index, "is_deleted"],
    form
  );
  useEffect(() => {
    if (sellPrice && netCostPrice) {
      form.setFields([
        {
          name: ["billing_information", field.name, "billing_profit"],
          value: (sellPrice || 0) - netCostPrice,
        },
      ]);
    }
  }, [sellPrice, netCostPrice, form]);

  useEffect(() => {
    if (quantity && sellUnitPrice) {
      form.setFields([
        {
          name: ["billing_information", field.name, "billing_total_sales"],
          value: Number(quantity) * Number(sellUnitPrice),
        },
      ]);
    }
  }, [quantity, sellUnitPrice, form]);

  useEffect(() => {
    form.setFields([
      {
        name: ["billing_information", field.name, "billing_cost_price"],
        value: totalCost,
      },
    ]);
    if (totalCost && quantity) {
      form.setFields([
        {
          name: ["billing_information", field.name, "billing_total_cost_price"],
          value: Number(totalCost) * quantity,
        },
      ]);
    }
  }, [totalCost, form, quantity]);

  //----------- calculate agent commission-----------------------------
  const { data } = useGetAllAgentProfileQuery();
  const invoice_agent_id: number | undefined = useWatch(
    "invoice_agent_id",
    form
  );
  const getAllAgentProfile = data?.data;
  const billing_profit = useWatch(["billing_information"], form);
  const sumTotalProfit = billing_profit?.reduce(
    (acc: number, curr: any) => acc + Fixed2(curr?.billing_profit),
    0
  );
  useEffect(() => {
    if (invoice_agent_id) {
      const agentInfo = getAllAgentProfile?.find(
        (item) => item?.agent_id === invoice_agent_id
      );

      form.setFieldValue(
        ["invoice_agent_com_amount"],
        Fixed2(
          (Number(agentInfo?.agent_commission_rate || 0) / 100) *
            Number(sumTotalProfit || 0)
        )
      );
    }
  }, [invoice_agent_id, sumTotalProfit]);

  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    if (disableRemoveButtonIndex && disableRemoveButtonIndex > index) {
      setDisabled(true);
    }
  }, [is_deleted, disableRemoveButtonIndex]);

  return (
    <Row
      style={is_deleted === 1 ? { backgroundColor: "#FFC0CB" } : {}}
      align="bottom"
      gutter={[10, 0]}
    >
      <SelectProduct
        label="Product"
        name={[field.name, "billing_product_id"]}
        size={4}
        placeholder="Select Product"
        required
        disabled={disabled}
      />
      <FormInputItem
        label="Pax Name"
        name={[field.name, "pax_name"]}
        size={4}
        disabled={disabled}
      />
      <FormInputItem
        label="Description"
        name={[field.name, "billing_description"]}
        size={4}
        disabled={disabled}
      />
      <NumberInput
        label="Unit Price"
        required
        name={[field.name, "billing_unit_price"]}
        size={4}
        disabled={disabled}
        min="0"
      />
      <NumberInput
        label="Quantity"
        required
        name={[field.name, "billing_quantity"]}
        size={4}
        disabled={disabled}
        min="0"
      />

      <NumberInput
        label="Total Sales Price"
        name={[field.name, "billing_total_sales"]}
        size={4}
        readOnly
        min="0"
        disabled={requireSellingPrice}
      />
      <NumberInput
        label="Cost Price"
        name={[field.name, "billing_cost_price"]}
        size={4}
        min="0"
        readOnly
        disabled={disabled}
      />
      <NumberInput
        label="Total Cost Price"
        name={[field.name, "billing_total_cost_price"]}
        size={4}
        min="0"
        readOnly
        disabled={disabled}
      />
      <NumberInput
        label="Profit"
        name={[field.name, "billing_profit"]}
        size={3}
        readOnly
        min="0"
        maxChar={14}
        disabled={disabled}
      />

      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[field.name, "is_deleted"]} />

      {/* {field.name === 0 ? (
        <Col lg={1}>
          <Form.Item label={false}>
            <Button type="primary" onClick={() => add()}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Col>
      ) : (
        <Col lg={1}>
          <Form.Item label={false}>
            <Button
              type="primary"
              disabled={!!is_deleted}
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  "billing_information",
                  field.name,
                  "is_deleted",
                ]);
                form.setFieldValue(
                  ["billing_information", field.name, "is_deleted"],
                  Number(!prev)
                );

                if (id) {
                  if (
                    (billing_information?.length ||
                      billing_information?.length === 0) &&
                    index >= billing_information.length
                  )
                    remove(field.name);
                } else {
                  remove(field.name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        </Col>
      )} */}
    </Row>
  );
};

export default InvoiceOtherPackageBillingInfoForm;
