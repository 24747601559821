import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import {
  IViewBillingInfo,
  IViewPassportInfo,
} from "../../../common/types/commonInterfaces";
import { Fixed2 } from "../../../common/utils/common.utils";
import FormHeaderTitle from "../../Invoice(Visa)/Components/FormHeaderTitle";
import getInvoiceViewPageController from "../../Invoice_Air_Ticket/utils/ts/getInvoiceViewPageController";
import { IITransportInfo } from "../../Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types";
import { HotelInformation, TicketInfo } from "../Type/Invoce.other.interface";

type Props = {
  billing_information: IViewBillingInfo[];
  passport_information: IViewPassportInfo[];
  ticket_information: TicketInfo[];
  hotel_information: HotelInformation[];
  transport_informaion: IITransportInfo[];
};
const ViewInvoiceOtherBillingInfo = ({
  billing_information,
  passport_information,
  ticket_information,
  hotel_information,
  transport_informaion,
}: Props) => {
  const billingColumn: ColumnsType<IViewBillingInfo> = [
    {
      title: "SL",
      align: "center",
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Pax Name",
      dataIndex: "billing_pax_name",
      key: "billing_pax_name",
      render: (paxName) => (paxName ? paxName : "N/A"),
    },
    ...((billing_information?.some((item) => item.billing_description) && [
      {
        title: "Description",
        dataIndex: "billing_description",
        key: "billing_description",
      },
    ]) ||
      []),
    {
      title: "Quantity",
      dataIndex: "billing_quantity",
      key: "billing_quantity",
      align: "right",
    },
    {
      title: "Unit price",
      dataIndex: "billing_unit_price",
      align: "right",
    },
    {
      title: "Sub Total",
      dataIndex: "billing_total_sales",
      key: "billing_total_sales",
      align: "right",
    },
  ];
  const passportColumn: ColumnsType<IViewPassportInfo> = [
    {
      title: "SL",
      render: (_, data, index) => <> {index + 1} </>,
      align: "center",
      width: "2%",
    },
    {
      title: "Passport No",
      dataIndex: "passport_passport_no",
      key: "passport_passport_no",
      width: "11%",
    },
    {
      title: "Passport Name",
      dataIndex: "passport_name",
      key: "passport_name",
    },
    {
      title: "Mobile",
      dataIndex: "passport_mobile_no",
      width: "5%",
    },
    {
      title: "Email",
      dataIndex: "passport_email",
      width: "10%",
    },
    {
      title: "DOB",
      dataIndex: "passport_date_of_birth",
      key: "passport_date_of_birth",
      width: "12%",

      render: (_, data) => (
        <>
          {" "}
          {data?.passport_date_of_birth
            ? dayjs(data?.passport_date_of_birth).format("D/MM/YYYY")
            : undefined}{" "}
        </>
      ),
    },
    {
      title: "Expire Date",
      dataIndex: "passport_date_of_expire",
      key: "passport_date_of_expire",
      width: "12%",

      render: (_, data) => (
        <>
          {" "}
          {data?.passport_date_of_expire
            ? dayjs(data?.passport_date_of_expire).format("D/MM/YYYY")
            : undefined}{" "}
        </>
      ),
    },
    {
      title: "Country Name",
      dataIndex: "country_name",
      key: "country_name",
      width: "15%",
    },
  ];
  const ticketColumn: ColumnsType<TicketInfo> = [
    {
      title: "SL",
      render: (_, data, index) => <> {index + 1} </>,
      align: "center",
    },
    {
      title: "Ticket No",
      dataIndex: "ticket_no",
      key: "ticket_no",
    },
    {
      title: "PNR",
      dataIndex: "ticket_pnr",
      key: "ticket_pnr",
    },
    {
      title: "Route",
      dataIndex: "ticket_route",
      key: "ticket_route",
    },
    {
      title: "Journey Date",
      dataIndex: "ticket_journey_date",
      width: "150px",

      render: (_, data) => (
        <>
          {" "}
          {data?.ticket_journey_date
            ? dayjs(data?.ticket_journey_date).format("D/MM/YYYY")
            : undefined}{" "}
        </>
      ),
    },
    {
      title: "Return Date",
      dataIndex: "ticket_return_date",
      width: "150px",

      render: (_, data) => (
        <>
          {" "}
          {data?.ticket_return_date
            ? dayjs(data?.ticket_return_date).format("D/MM/YYYY")
            : undefined}{" "}
        </>
      ),
    },
  ];
  const hotelColumn: ColumnsType<HotelInformation> = [
    {
      title: "SL",
      render: (_, data, index) => <> {index + 1} </>,
      align: "center",
    },
    {
      title: "Hotel Name",
      dataIndex: "hotel_name",
      key: "hotel_name",
    },
    {
      title: "Room Type",
      dataIndex: "rtype_name",
      key: "rtype_name",
    },
    {
      title: "Check In Date",
      dataIndex: "hotel_check_in_date",
      width: "150px",

      render: (_, data) => (
        <>
          {" "}
          {data?.hotel_check_in_date
            ? dayjs(data?.hotel_check_in_date).format("D/MM/YYYY")
            : undefined}{" "}
        </>
      ),
    },
    {
      title: "Check Out Date",
      dataIndex: "hotel_check_out_date",
      width: "150px",

      render: (_, data) => (
        <>
          {" "}
          {data?.hotel_check_out_date
            ? dayjs(data?.hotel_check_out_date).format("D/MM/YYYY")
            : undefined}{" "}
        </>
      ),
    },
  ];
  const transPortColumn: ColumnsType<any> = [
    {
      title: "SL",
      render: (_, data, index) => <> {index + 1} </>,
      align: "center",
    },
    {
      title: "Reference",
      dataIndex: "transport_reference_no",
      key: "transport_reference_no",
    },
    {
      title: "Pickup",
      dataIndex: "transport_pickup_place",
      key: "transport_pickup_place",
    },
    {
      title: "Dropoff",
      dataIndex: "transport_drop_off_place",
      key: "transport_drop_off_place",
    },
  ];

  // @HANDLE PRINT DOUBLE PAGE
  // states, variables and references
  const passport_information_ref = useRef<HTMLDivElement>(null);
  const ticket_information_ref = useRef<HTMLDivElement>(null);
  const transport_informaion_ref = useRef<HTMLDivElement>(null);
  const hotel_information_ref = useRef<HTMLDivElement>(null);
  const billing_information_ref = useRef<HTMLDivElement>(null);

  const [pageController, setPageController] = useState<
    {
      identifier: string;
      marginTop: number;
      bottomIdentifier: string;
    }[]
  >();

  useEffect(() => {
    const passport_information_height =
      Fixed2(passport_information_ref.current?.clientHeight) / 96; // value in inch
    const ticket_information_height =
      Fixed2(ticket_information_ref.current?.clientHeight) / 96; // value in inch
    const transport_informaion_height =
      Fixed2(transport_informaion_ref.current?.clientHeight) / 96; // value in inch
    const hotel_information_height =
      Fixed2(hotel_information_ref.current?.clientHeight) / 96; // value in inch
    const billing_information_height =
      Fixed2(billing_information_ref.current?.clientHeight) / 96; // value in inch

    const pageControl: any = getInvoiceViewPageController({
      topHeight: 2.5,
      footerHeight: 2.5,
      pageHeight: 11.7,
      items: [
        {
          height: passport_information_height,
          identifier: "passport_information",
        },
        {
          height: ticket_information_height,
          identifier: "ticket_information",
        },
        {
          height: transport_informaion_height,
          identifier: "transport_informaion",
        },
        {
          height: hotel_information_height,
          identifier: "hotel_information",
        },
        {
          height: billing_information_height,
          identifier: "billing_information",
        },
        {
          height: 5,
          identifier: "footer",
        },
      ],
    });

    setPageController(pageControl);
  }, [
    billing_information,
    passport_information,
    ticket_information,
    hotel_information,
    transport_informaion,
  ]);

  return (
    <div>
      {/* index 0 */}
      <div ref={passport_information_ref}>
        {passport_information?.length ? (
          <>
            <FormHeaderTitle title="Passport Information" />
            <Table
              size="small"
              bordered
              rowKey={(e) => Math.random()}
              className="invoiceBillingTable"
              rowClassName={"invoiceBillingTd"}
              dataSource={passport_information}
              columns={passportColumn}
              pagination={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {/* index 1 */}
      <div
        ref={ticket_information_ref}
        style={{
          marginTop:
            pageController &&
            pageController[1].identifier === "ticket_information"
              ? `${pageController[1].marginTop}in`
              : 0,
          // Add border top for identify page end
          borderTop:
            pageController &&
            pageController[1].identifier === "ticket_information" &&
            pageController[1].marginTop
              ? "4px dashed lightgray"
              : "",
          paddingTop:
            pageController &&
            pageController[1].identifier === "ticket_information" &&
            pageController[1].marginTop
              ? "0.5in"
              : "",
        }}
      >
        {ticket_information?.length ? (
          <>
            <FormHeaderTitle title="Ticket Information" />
            <Table
              size="small"
              bordered
              rowKey={(e) => Math.random()}
              rowClassName={"invoiceBillingTd"}
              dataSource={ticket_information}
              columns={ticketColumn}
              pagination={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {/* index 2 */}
      <div
        ref={transport_informaion_ref}
        style={{
          // billing margin control
          marginTop:
            pageController &&
            pageController[2].identifier === "transport_informaion"
              ? `${pageController[2].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[2].identifier === "transport_informaion" &&
            pageController[2].marginTop
              ? "4px dashed lightgray"
              : "",
          paddingTop:
            pageController &&
            pageController[2].identifier === "transport_informaion" &&
            pageController[2].marginTop
              ? "0.5in"
              : "",
        }}
      >
        {transport_informaion?.length ? (
          <>
            <FormHeaderTitle title="Transport Information" />
            <Table
              size="small"
              bordered
              rowKey={(e) => Math.random()}
              className="invoiceBillingTable"
              rowClassName={"invoiceBillingTd"}
              dataSource={transport_informaion}
              columns={transPortColumn}
              pagination={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {/* index 3 */}
      <div
        ref={hotel_information_ref}
        style={{
          // hotel_information margin control
          marginTop:
            pageController &&
            pageController[3].identifier === "hotel_information"
              ? `${pageController[3].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[3].identifier === "hotel_information" &&
            pageController[3].marginTop
              ? "4px dashed lightgray"
              : "",
          paddingTop:
            pageController &&
            pageController[3].identifier === "hotel_information" &&
            pageController[3].marginTop
              ? "0.5in"
              : "",
        }}
      >
        {hotel_information?.length ? (
          <>
            <FormHeaderTitle title="Hotel Information" />
            <Table
              size="small"
              bordered
              rowKey={(e) => Math.random()}
              className="invoiceBillingTable"
              rowClassName={"invoiceBillingTd"}
              dataSource={hotel_information}
              columns={hotelColumn}
              pagination={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {/* index 4 */}
      <div
        ref={billing_information_ref}
        style={{
          // footer margin control
          marginTop:
            pageController &&
            pageController[4].identifier === "billing_information"
              ? `${pageController[4].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[4].identifier === "billing_information" &&
            pageController[4].marginTop
              ? "4px dashed lightgray"
              : "",
          paddingTop:
            pageController &&
            pageController[4].identifier === "billing_information" &&
            pageController[4].marginTop
              ? "0.5in"
              : "",
        }}
      >
        {billing_information?.length ? (
          <>
            <FormHeaderTitle title="Billing Information" />
            <Table
              size="small"
              bordered
              rowKey={(e) => Math.random()}
              className="invoiceBillingTable"
              rowClassName={"invoiceBillingTd"}
              dataSource={billing_information}
              columns={billingColumn}
              pagination={false}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {/* index 5 */}
      {/* THIS IS CREATE ONLY FOR CONTROLL FOOTER */}
      <div
        style={{
          // footer margin control
          marginTop:
            pageController && pageController[5].identifier === "footer"
              ? `${pageController[5].marginTop}in`
              : 0,
          borderTop:
            pageController &&
            pageController[5].identifier === "footer" &&
            pageController[5].marginTop
              ? "4px dashed lightgray"
              : "",
          paddingTop:
            pageController &&
            pageController[5].identifier === "footer" &&
            pageController[5].marginTop
              ? "0.5in"
              : "",
        }}
      ></div>
    </div>
  );
};

export default ViewInvoiceOtherBillingInfo;
