import { message } from "antd";
import { api } from "../../../app/baseQuery";
import {
  IAllInvoices,
  IViewInvoiceDetails,
} from "../../../common/types/commonInterfaces";
import { HTTPResponse, voidType } from "../../../common/types/commonTypes";
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from "../../Reports/Api/constants";
import {
  IInvoiceDetails,
  IInvoiceOtherFormattedValueType,
  IInvoiceOtherPackageCosting,
  IInvoiceOtherPackageView,
} from "../Type/Invoce.other.interface";
import { MONEY_RECEIPT_TAG } from "../../Money_Receipt/api/endpoints/moneyReceiptEndpoints";
import { DASHBOARD } from "../../Dashboard/Api/Constants/Constants";

export const invoiceVisaEndponts = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * get all advance return vendors
     *
     */

    // GET ALL
    getAllInvoiceOtherPackage: build.query<
      HTTPResponse<IAllInvoices[]>,
      string
    >({
      query: (query) => ({
        url: `/invoice_other_package?${query}`,
      }),
      providesTags: () => [
        "invoices",
        "invoice-other-package",
        { type: MONEY_RECEIPT_TAG },
      ],
    }),

    // VIEW DETAILS
    getViewInvoiceOtherPackage: build.query<HTTPResponse<any>, number>({
      query: (id) => ({
        url: `/invoice_other_package/${id}`,
      }),
      providesTags: () => ["invoice-other-package", "invoices"],
    }),

    // DELETE AND RESTORE
    deleteInvoiceOtherPackage: build.mutation<HTTPResponse<void>, number>({
      query: (id) => ({
        url: `/invoice_other_package/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "invoices",
        "invoice-other-package",
        INVALIDATE_CLIENT_VENDOR_LEDGER,
        DASHBOARD,
      ],
    }),

    getTransportType: build.query<
      HTTPResponse<{ ttype_id: number; ttype_name: string }[]>,
      void
    >({
      query: () => ({
        url: "/invoice-others/transport-type",
      }),
      providesTags: () => [{ type: "invoice-other-package" }],
    }),

    getHotelRoomType: build.query<
      HTTPResponse<{ rtype_id: number; rtype_name: string }[]>,
      void
    >({
      query: () => ({
        url: "/hotel-room/get-all",
      }),
      providesTags: () => [{ type: "invoice-other-package" }],
    }),

    getInvoiceOtherDetails: build.query<HTTPResponse<IInvoiceDetails>, number>({
      query: (invoice_id) => ({
        url: `invoice-others/get-for-edit/${invoice_id}`,
      }),
      providesTags: () => [{ type: "invoice-other-package" }],
    }),

    postInvoiceOtherPackage: build.mutation<
      HTTPResponse<any>,
      IInvoiceOtherFormattedValueType
    >({
      query: (body) => ({
        url: "/invoice_other_package",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "invoice-other-package" },
        { type: "invoices" },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
    postInvoiceOtherPackageCosting: build.mutation<
      HTTPResponse<void>,
      { body: IInvoiceOtherPackageCosting; id: number }
    >({
      query: ({ body, id }) => ({
        url: `/invoice_other_package/add-costing/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [
        { type: "invoice-other-package" },
        { type: "invoices" },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    updateInvoiceOtherPackage: build.mutation<
      HTTPResponse<void>,
      { id: number; body: IInvoiceOtherFormattedValueType }
    >({
      query: ({ id, body }) => ({
        url: `/invoice_other_package/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [
        { type: "invoice-other-package" },
        { type: "invoices" },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    //Void Invoice Other AirTicket
    voidOtherInvAirTicket: build.mutation<void, voidType>({
      query: (body) => ({
        url: `/invoice-others/void/${body.id}`,
        method: "PUT",
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error("Some things went to wrong");
        }
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success("Void Successfully");
      },

      invalidatesTags: [
        "invoice-other-package",
        "invoices",
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
  }),
});

export const {
  useLazyGetAllInvoiceOtherPackageQuery,
  useGetAllInvoiceOtherPackageQuery,
  useDeleteInvoiceOtherPackageMutation,
  useUpdateInvoiceOtherPackageMutation,
  useGetHotelRoomTypeQuery,
  useGetInvoiceOtherDetailsQuery,
  useGetTransportTypeQuery,
  useGetViewInvoiceOtherPackageQuery,
  useLazyGetViewInvoiceOtherPackageQuery,
  usePostInvoiceOtherPackageMutation,
  usePostInvoiceOtherPackageCostingMutation,
  useVoidOtherInvAirTicketMutation,
} = invoiceVisaEndponts;
