import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { findInvoiceRoute } from "../../../../layout/components/SearchInvoices";
import { IDailySalesReport } from "../types/DailySalesReportTypes";
import { Tag } from "antd";

interface Props {
  pagination: {
    current: number;
    pageSize: number;
  };
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined;
}

export const DailySalesReportColumn = ({
  pagination,
  paramData,
}: Props): ColumnsType<IDailySalesReport> => {
  return [
    {
      title: "SL.",
      dataIndex: "key",
      key: "key",
      align: "center",

      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "sales_date",
      key: "sales_date",
      render(value, record, index) {
        return dayjs(record.sales_date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      key: "invoice_no",
      render: (_, data) => (
        <Link
          to={
            "../" +
            findInvoiceRoute(data.invoice_category_id) +
            "/" +
            "details" +
            "/" +
            data.invoice_id
          }
          state={location.pathname}
        >
          {data.invoice_no}
        </Link>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },
    {
      title: "Category",
      dataIndex: "invcat_title",
      key: "invcat_title",
    },

    {
      title: "Sales By",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (_, record) => (
        <Link
          to={`/reports/sales_man_collection_report?${paramData?.date_range}&employee_id=${record.employee_id}`}
        >
          {" "}
          {record.employee_name}
        </Link>
      ),
    },
    {
      title: "Sales Price",
      dataIndex: "invoice_net_total",
      key: "invoice_net_total",
      align: "right",
    },
    {
      title: "Cost Price",
      dataIndex: "cost_price",
      key: "cost_price",
      align: "right",
    },
    {
      title: "Discount",
      dataIndex: "invoice_discount",
      key: "invoice_discount",
      align: "right",
    },
    {
      title: "Service Charge",
      dataIndex: "invoice_service_charge",
      key: "invoice_service_charge",
      align: "right",
    },
    {
      title: "Profit",
      dataIndex: "profit_amount",
      key: "profit_amount",
      render: (value) => (
        <span className={Number(value || 0) > 0 ? "text-profit" : "text-loss"}>
          {Number(value || 0)}
        </span>
      ),
      align: "right",
    },
    {
      title: "Collect Amount",
      dataIndex: "client_pay_amount",
      key: "client_pay_amount",
      align: "right",
    },
    {
      title: "Due Amount",
      dataIndex: "due_amount",
      key: "due_amount",
      render: (column) => {
        return Number(column || 0) >= 0 ? (
          <span className={"text-red"}>{column}</span>
        ) : (
          <Tag color="green">PAID</Tag>
        );
      },
      align: "right",
    },
  ];
};
