import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Row } from "antd";
import { useWatch } from "antd/es/form/Form";

import { useState, useEffect } from "react";
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from "../../../components/common/FormItem/FormItems";
import {
  SelectRoomType,
  SelectVendors,
} from "../../../components/common/FormItem/SelectCustomFeilds";
import { IHotelInfo } from "../Type/Invoce.other.interface";
import BillingIsDeleted from "../../Invoice(Visa)/Components/BillingIsDeleted";
import { useParams } from "react-router";

type Props = {
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
  name: any;
  index: number;
  hotel_info: IHotelInfo[] | undefined;
  invComClientInfo?: string;
};

function Hotel_Details_List_Info({
  add,
  remove,
  form,
  name,
  index,
  hotel_info,
  invComClientInfo,
}: Props) {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [instanceVendorTicket, setInstanceVendorTicket] = useState<number>();

  const params = useParams();
  const id = params.id || !!hotel_info;
  const hotel_name = useWatch(["hotel_information", name, "hotel_name"], form);
  const hotel_cost_price = useWatch(
    ["hotel_information", name, "hotel_cost_price"],
    form
  );
  const hotel_vendor_id = useWatch(
    ["hotel_information", name, "comb_vendor_id"],
    form
  );
  const hotel_reference_no = useWatch(
    ["hotel_information", name, "hotel_reference_no"],
    form
  );
  const hotel_check_out_date = useWatch(
    ["hotel_information", name, "hotel_check_out_date"],
    form
  );
  const hotel_check_in_date = useWatch(
    ["hotel_information", name, "hotel_check_in_date"],
    form
  );
  const hotel_room_type_id = useWatch(
    ["hotel_information", name, "hotel_room_type_id"],
    form
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (
      hotel_name ||
      hotel_reference_no ||
      hotel_check_out_date ||
      hotel_check_in_date ||
      hotel_room_type_id
    ) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
      timeoutId = setTimeout(() => {
        form.validateFields([
          ["hotel_information", name, "hotel_name"],
          ["hotel_information", name, "hotel_reference_no"],
          ["hotel_information", name, "hotel_check_out_date"],
          ["hotel_information", name, "hotel_check_in_date"],
          ["hotel_information", name, "hotel_room_type_id"],
        ]);
      });
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    hotel_name,
    hotel_reference_no,
    hotel_check_out_date,
    hotel_check_in_date,
    hotel_room_type_id,
  ]);
  const [disabled, setDisabled] = useState(false);
  const is_deleted: 0 | 1 = useWatch(
    ["hotel_information", index, "is_deleted"],
    form
  );

  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [is_deleted]);
  return (
    <Row
      style={
        is_deleted === 1
          ? {
              backgroundColor: "#FFC0CB",
              display: "flex",
              alignItems: "center",
            }
          : { display: "flex", alignItems: "center" }
      }
      gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
    >
      <FormInputItem
        name={[name, "hotel_name"]}
        label="Hotel Name"
        disabled={disabled}
        size={5}
      />
      <FormInputItem
        name={[name, "hotel_reference_no"]}
        label="Reference No	"
        size={3}
        disabled={disabled}
      />

      <DateInput
        name={[name, "hotel_check_in_date"]}
        label="Check In Date"
        size={3}
        disabled={disabled}
      />

      <DateInput
        name={[name, "hotel_check_out_date"]}
        label="Check Out Date"
        size={3}
        disabled={disabled}
      />

      <SelectRoomType
        name={[name, "hotel_room_type_id"]}
        label="Room type"
        size={3}
        disabled={disabled}
      />
      <NumberInput
        label="Cost Price"
        name={[name, "hotel_cost_price"]}
        size={3}
        required={hotel_vendor_id}
        maxChar={14}
        minChar={0}
        disabled={disabled}
      />
      <SelectVendors
        label="Vendor"
        size={3}
        name={[name, "comb_vendor_id"]}
        disabled={disabled}
        required={hotel_cost_price}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value: string) {
              if (getFieldValue("comb_vendor_id")) {
                if (getFieldValue("comb_vendor_id") === value) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              }
              if (invComClientInfo !== undefined) {
                if (invComClientInfo === value) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              }
              return Promise.resolve();
            },
            message: "Client and vendor can't be same!",
          }),
        ]}
        setInstanceVendorSelect={setInstanceVendorTicket}
      />
      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[index, "is_deleted"]} />
      {index === 0 ? (
        <Button
          type="primary"
          style={{ marginTop: "7px" }}
          onClick={() => add()}
        >
          <PlusOutlined />
        </Button>
      ) : (
        <Form.Item label={false}>
          <Button
            type="primary"
            danger
            style={{ marginTop: "30px" }}
            disabled={!!is_deleted}
            onClick={() => {
              const prev = form.getFieldValue([
                "hotel_information",
                index,
                "is_deleted",
              ]);
              form.setFieldValue(
                ["hotel_information", index, "is_deleted"],
                Number(!prev)
              );
              if (id) {
                if (
                  (hotel_info?.length || hotel_info?.length === 0) &&
                  index >= hotel_info.length
                )
                  remove(name);
              } else {
                remove(name);
              }
            }}
          >
            <MinusCircleOutlined />
          </Button>
        </Form.Item>
      )}
    </Row>
  );
}

export default Hotel_Details_List_Info;
