import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BreadCrumb from "../../breadCrumb/BreadCrumb";

import dayjs from "dayjs";
import { useHotkeys } from "react-hotkeys-hook";
import { useAppSelector } from "../../../../app/hooks";
import { IViewInvoiceDetails } from "../../../../common/types/commonInterfaces";
import RefundModal from "../../../../modules/Invoices/common/RefundModal";
import TabInvoiceADM from "./TabInvoiceADM";
import TabInvoiceVoid, { handleCancel, handleOk } from "./void/TabInvoiceVoid";

type Props = {
  invoice_category_id?: number;
  invoice_id: number;
  selectedPrintableRef: React.RefObject<HTMLDivElement>;
  links?: { addLink: string; breadcrumbTitle: string };
  invoiceData: IViewInvoiceDetails | undefined;
};

export interface IADM {
  inv_cat?: number;
  inv_id?: number;
  open: boolean;
}

export const portraitPageStyle = `
    @page {
      size: A4 portrait;
    }
    @media print {
      body {
   

      }
    }
  `;

const ViewInvoiceHeader = ({
  invoice_category_id,
  selectedPrintableRef,
  links,
  invoice_id,
  invoiceData,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVoid, setIsVoid] = useState(false);
  const [adm, setAdm] = useState<IADM>({
    inv_cat: undefined,
    inv_id: undefined,
    open: false,
  });

  // BREADCRUMB, LINKS
  let addLink = "";
  let breadcrumbTitle = "";
  switch (invoice_category_id) {
    case 1:
      breadcrumbTitle = "Invoice Airticket";
      addLink = "/airticket";
      break;
    case 2:
      breadcrumbTitle = "Invoice Airticket Non Commission";
      addLink = "/noncomairticket";
      break;
    case 3:
      breadcrumbTitle = "Invoice Airticket Reissue";
      addLink = "/reissueairticket";
      break;
    case 4:
      breadcrumbTitle = "Invoice Tour Package";
      addLink = "/tour-invoice/all";
      break;
    case 5:
      breadcrumbTitle = "Invoice Other";
      addLink = "/invoiceother";
      break;
    case 10:
      breadcrumbTitle = "Invoice Visa";
      addLink = "/visa";
      break;
    case 26:
      breadcrumbTitle = "Invoice Ummrah";
      addLink = "/invoiceumrah";
      break;
    case 30:
      breadcrumbTitle = "Invoice Hajj Pre Registration";
      addLink = "/hajjpreregistration";
      break;
    case 31:
      breadcrumbTitle = "Invoice Hajj";
      addLink = "/invoicehajj";
      break;
    case 81:
      breadcrumbTitle = "Invoice Other Package";
      addLink = "/invoice-other-package";
      break;
    default:
  }

  // REFUND FROM VIEW INVOICE : REISSUE/UMMRAH/HAJJ
  const isRefundAble = [1, 3, 26, 31].includes(invoice_category_id || 0);

  if (links) {
    breadcrumbTitle = links.breadcrumbTitle;
    addLink = links.addLink;
  }

  const org_name = useAppSelector(
    (state) => state.user?.organization_info.org_name
  );

  const handlePrint = useReactToPrint({
    content: () => selectedPrintableRef.current,
    pageStyle: portraitPageStyle,
    removeAfterPrint: true,
    documentTitle: `${org_name}-${invoiceData?.invoice_no}-${dayjs().unix()}`,
  });

  useHotkeys("ctrl + p", (e) => {
    e.preventDefault();
    handlePrint();
  });

  const voidInvoice = () => {
    setIsModalOpen(true);
  };

  const IsInvoiceVisa = invoiceData?.invoice_no.includes("IV");

  useEffect(() => {
    if (invoiceData?.invoice_date) {
      const targetDate = new Date(
        invoiceData?.invoice_date || new Date()
      ) as any;

      const currentDate = new Date() as any;
      const timeDifference = currentDate - targetDate;
      const hoursDifference = Math.abs(timeDifference) / 36e5;
      // if (hoursDifference > 24) {
      //   setIsVoid(false);
      // } else {
      //   setIsVoid(true);
      // }
    }
  }, [invoiceData?.invoice_date]);

  // =============== HANDLER
  const handleAdm = () => {
    setAdm((prev) => ({
      ...prev,
      open: true,
      inv_cat: invoice_category_id,
      inv_id: invoice_id,
    }));
  };

  return (
    <>
      <BreadCrumb arrOfOption={[breadcrumbTitle, "View"]} />
      <Row wrap gutter={[5, 0]}>
        <Col className="commonBox">
          <Link to={addLink}>
            <Button icon={<ArrowLeftOutlined />} type="primary">
              Return to Invoice List
            </Button>
          </Link>
        </Col>
        <Col className="commonBox">
          <Button
            onClick={handlePrint}
            icon={<PrinterOutlined />}
            type="primary"
          >
            Print
          </Button>
        </Col>

        {IsInvoiceVisa ||
        (!invoiceData?.refunds?.client_refund?.length &&
          !invoiceData?.refunds?.vendor_refund?.length) ? (
          <Col className="commonBox">
            <Button
              onClick={voidInvoice}
              icon={<CloseCircleOutlined />}
              type="primary"
              danger
            >
              Void
            </Button>
          </Col>
        ) : (
          ""
        )}

        {isRefundAble && (
          <RefundModal
            title={breadcrumbTitle}
            invoiceId={invoice_id}
            invoiceNo={invoiceData?.invoice_no as string}
            invoice_category_id={invoice_category_id}
          />
        )}

        {/* <Col className='commonBox'>
          <Button
            style={{ background: 'purple', color: 'white' }}
            onClick={handleAdm}
            icon={<CloseCircleOutlined />}
            type='text'
          >
            ADM
          </Button>
        </Col> */}
      </Row>

      <Modal
        title="Void Invoice"
        open={isModalOpen}
        onOk={() => handleOk(setIsModalOpen)}
        onCancel={() => handleCancel(setIsModalOpen)}
        footer={false}
        width={800}
      >
        <TabInvoiceVoid
          invoice_category_id={invoice_category_id}
          invoice_id={invoice_id}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>

      {adm.inv_id && <TabInvoiceADM setAdm={setAdm} adm={adm} />}
    </>
  );
};

export default ViewInvoiceHeader;
