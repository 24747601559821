import { ColumnsType } from "antd/es/table";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import {
  IViewBillingInfo,
  IViewInvoiceDetails,
  IViewPassportInfo,
} from "../../../common/types/commonInterfaces";
import { isNotEmpty } from "../../../common/utils/common.utils";
import CostViewSubTotal from "../../../components/common/Invoice/ViewInvoiceInfo/CostViewSubTotal";
import FormHeaderTitle from "../../Invoice(Visa)/Components/FormHeaderTitle";
import { IITransportInfo } from "../../Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types";
import { HotelInformation, TicketInfo } from "../Type/Invoce.other.interface";

type Props = {
  invoiceDetails: IViewInvoiceDetails;
};

const ticketInfoColumn: ColumnsType<TicketInfo> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Ticket No",
    dataIndex: "ticket_no",
    key: "ticket_no",
  },
  {
    title: "PNR",
    dataIndex: "ticket_pnr",
    key: "ticket_pnr",
  },
  {
    title: "Route",
    dataIndex: "ticket_route",
    key: "ticket_route",
    width: "90px",
  },
  {
    title: "Airline",
    dataIndex: "airline_name",
    key: "airline_name",
    width: "150px",
  },
  {
    title: "Reference No",
    dataIndex: "ticket_reference_no",
    key: "ticket_reference_no",
    width: "60px",
  },
  {
    title: "Journey Date",
    dataIndex: "ticket_journey_date",
    key: "ticket_journey_date",
    width: "120px",
    render: (_, data) => {
      return (
        <>
          {data?.ticket_journey_date
            ? dayjs(data.ticket_journey_date).format("DD-MM-YYYY")
            : null}
        </>
      );
    },
  },
  {
    title: "Return Date",
    dataIndex: "ticket_return_date",
    key: "ticket_return_date",
    width: "120px",
    render: (_, data) => {
      return (
        <>
          {data?.ticket_return_date
            ? dayjs(data.ticket_return_date).format("DD-MM-YYYY")
            : null}
        </>
      );
    },
  },
  {
    title: "Cost",
    dataIndex: "ticket_cost_price",
    key: "ticket_cost_price",
  },
  {
    title: "Vendor",
    dataIndex: "vendor_name",
    key: "vendor_name",
    width: "120px",
  },
];
const hotelInfoColumn: ColumnsType<HotelInformation> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Hotel Name",
    dataIndex: "hotel_name",
    key: "hotel_name",
  },
  {
    title: "Reference No",
    dataIndex: "hotel_reference_no",
    key: "hotel_reference_no",
  },
  {
    title: "Check In Date",
    dataIndex: "hotel_check_in_date",
    key: "hotel_check_in_date",
    render: (_, data) => {
      return (
        <>
          {data?.hotel_check_in_date
            ? dayjs(data.hotel_check_in_date).format("DD-MM-YYYY")
            : null}
        </>
      );
    },
  },
  {
    title: "Check Out Date",
    dataIndex: "hotel_check_out_date",
    key: "hotel_check_out_date",
    render: (_, data) => {
      return (
        <>
          {data?.hotel_check_out_date
            ? dayjs(data.hotel_check_out_date).format("DD-MM-YYYY")
            : null}
        </>
      );
    },
  },
  {
    title: "Room type",
    dataIndex: "rtype_name",
    key: "rtype_name",
  },
  {
    title: "Cost",
    dataIndex: "hotel_cost_price",
    key: "hotel_cost_price",
  },
  {
    title: "Vendor",
    dataIndex: "vendor_name",
    key: "vendor_name",
    width: "120px",
  },
];

const clientRefundInfoColumn: ColumnsType<IITransportInfo> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Product",
    dataIndex: "transport_type_id",
    key: "transport_type_id",
  },
  {
    title: "Qty",
    dataIndex: "transport_reference_no",
    key: "transport_reference_no",
  },
  {
    title: "Unit Price",
    dataIndex: "transport_pickup_place",
    key: "transport_pickup_place",
  },
  {
    title: "Total Price",
    dataIndex: "transport_pickup_place",
    key: "transport_pickup_place",
  },
  {
    title: "Charge Taken from client",
    dataIndex: "transport_pickup_place",
    key: "transport_pickup_place",
  },
];
const transportInfoColumn: ColumnsType<IITransportInfo> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Transport Type",
    dataIndex: "ttype_name",
    key: "ttype_name",
  },
  {
    title: "Reference No",
    dataIndex: "transport_reference_no",
    key: "transport_reference_no",
  },
  {
    title: "Pickup Place",
    dataIndex: "transport_pickup_place",
    key: "transport_pickup_place",
  },
  {
    title: "Pickup Time",
    render: (_, data) => {
      return (
        <>
          {data?.transport_pickup_time
            ? dayjs(data.transport_pickup_time).format("HH-mm-ss")
            : null}
        </>
      );
    },
  },
  {
    title: "Drop Off Place",
    dataIndex: "transport_drop_off_place",
    key: "transport_drop_off_place",
  },
  {
    title: "Dropoff Time",
    render: (_, data) => {
      return (
        <>
          {data?.transport_drop_off_time
            ? dayjs(data.transport_drop_off_time).format("HH-mm-ss")
            : null}
        </>
      );
    },
  },
  {
    title: "Cost",
    dataIndex: "transport_cost_price",
    key: "transport_cost_price",
  },
  {
    title: "Vendor",
    dataIndex: "vendor_name",
    key: "vendor_name",
    width: "120px",
  },
];
const billingInfoColumn: ColumnsType<IViewBillingInfo> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Product Name",
    dataIndex: "product_name",
    key: "product_name",
    align: "center",
  },
  {
    title: "Pax Name",
    dataIndex: "billing_pax_name",
    key: "billing_pax_name",
    align: "center",
  },
  {
    title: "Quantity",
    dataIndex: "billing_quantity",
    key: "billing_quantity",
    align: "center",
  },
  {
    title: "Unit price",
    dataIndex: "billing_unit_price",
    align: "center",
  },
  {
    title: "Total Cost",
    dataIndex: "billing_cost_price",
    key: "billing_cost_price",
    align: "center",
  },
  {
    title: "Total Sales",
    dataIndex: "billing_total_sales",
    key: "billing_total_sales",
    align: "center",
  },
  {
    title: "Profit",
    align: "right",
    render: (record) => {
      const profit =
        Number(record?.billing_total_sales || 0) -
        Number(record?.billing_cost_price || 0);
      return (
        <span className={profit > 0 ? "text-profit" : "text-loss"}>
          {profit}
        </span>
      );
    },
  },
];
const passportInfoColumn: ColumnsType<IViewPassportInfo> = [
  {
    title: "SL.",
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: "Passport No",
    dataIndex: "passport_passport_no",
    key: "passport_passport_no",
  },
  {
    title: "Name",
    dataIndex: "passport_name",
    key: "passport_name",
  },
  {
    title: "Mobile",
    dataIndex: "passport_mobile_no",
    key: "passport_mobile_no",
  },
  {
    title: "Email",
    dataIndex: "passport_email",
    key: "passport_email",
  },
  {
    title: "Date of Birth",
    dataIndex: "passport_date_of_birth",
    key: "passport_date_of_birth",
    render: (_, data) => {
      return <>{dayjs(data.passport_date_of_birth).format("DD-MM-YYYY")}</>;
    },
  },
  {
    title: "Date of issue",
    dataIndex: "passport_date_of_issue",
    key: "passport_date_of_issue",
    render: (_, data) => {
      return <>{dayjs(data.passport_date_of_issue).format("DD-MM-YYYY")}</>;
    },
  },
  {
    title: "Date of expire",
    dataIndex: "passport_date_of_expire",
    key: "passport_date_of_expire",
    render: (_, data) => {
      return <>{dayjs(data.passport_date_of_expire).format("DD-MM-YYYY")}</>;
    },
  },
  {
    title: "Visiting Country",
    dataIndex: "country_name",
    key: "country_name",
  },
];
const ViewInvoiceOtherDetails = ({ invoiceDetails }: Props) => {
  const {
    ticketInfo,
    hotel_information,
    transport_information,
    billing_information,
    passport_information,
    refunds,
  } = invoiceDetails;
  return (
    <>
      {isNotEmpty(passport_information) && (
        <>
          <FormHeaderTitle title="PASSPORT INFO" />
          <Table
            size="small"
            bordered
            rowKey={(e) => e.passport_passport_no}
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            dataSource={passport_information}
            columns={passportInfoColumn}
            pagination={false}
          />
        </>
      )}

      {isNotEmpty(ticketInfo) && (
        <div className="mt-5">
          <FormHeaderTitle title="TICKET INFO" />
          <Table
            size="small"
            bordered
            rowKey={(e) => e?.ticket_no as string}
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            dataSource={ticketInfo}
            columns={ticketInfoColumn}
            pagination={false}
          />
        </div>
      )}

      {isNotEmpty(hotel_information) && (
        <div className="mt-5">
          <FormHeaderTitle title="HOTEL INFO" />
          <Table
            size="small"
            bordered
            rowKey={(e) => e.hotel_name as string}
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            dataSource={hotel_information}
            columns={hotelInfoColumn}
            pagination={false}
          />
        </div>
      )}
      {isNotEmpty(transport_information) && (
        <div className="mt-5">
          <FormHeaderTitle title="TRANSPORT INFO" />
          <Table
            size="small"
            bordered
            rowKey={(e) => e.transport_type_id}
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            dataSource={transport_information}
            columns={transportInfoColumn}
            pagination={false}
          />
        </div>
      )}
      {isNotEmpty(refunds) && (
        <div className="mt-5">
          <FormHeaderTitle title="CLIENT REFUND INFORMATION" />
          <Table
            size="small"
            rowKey={(e) => e.transport_type_id}
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            // dataSource={refunds?.client_refund}
            columns={clientRefundInfoColumn}
            pagination={false}
          />
        </div>
      )}
      {isNotEmpty(billing_information) && (
        <div className="mt-5">
          <FormHeaderTitle title="BILLING INFO" />
          <Table
            bordered
            size="small"
            rowKey={(e) => e.product_name}
            className="invoiceBillingTable"
            rowClassName={"invoiceBillingTd"}
            dataSource={billing_information}
            columns={billingInfoColumn}
            pagination={false}
          />
        </div>
      )}
      {/* <CostViewSubTotal invoice_info={invoiceDetails} /> */}
    </>
  );
};

export default ViewInvoiceOtherDetails;
