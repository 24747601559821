import { Route } from "react-router";
import PermissionRoute from "../../auth/components/PermissionRoute";
import { moduleType } from "../../auth/states/userSlice";
import { crud, rolePermissionType } from "../../common/types/commonTypes";
import { getListPermission } from "../../common/utils/common.utils";
import EditInvoiceHajj from "../../modules/Hajj/Invoice_Hajj/Pages/EditInvoceHajj";
import NewInvoiceAddHajj from "../../modules/Hajj/Invoice_Hajj/Pages/NewInvoiceHajj";
import InvoiceHajjLists from "../../modules/Hajj/Invoice_Hajj/Pages/ViewInvoiceInfo/InvoiceHajjLists";
import ViewInvoiceHajj from "../../modules/Hajj/Invoice_Hajj/Pages/ViewInvoiceInfo/ViewInvoiceHajj";
import Hajj_Reg from "../../modules/Hajj/Invoice_Hajj_Reg/Pages/Hajj_Reg";
import CancelHajjRegAll from "../../modules/Hajji Management/Pages/CancelHajjReg/CancelHajjRegAll";
import PostCancelHajjReg from "../../modules/Hajji Management/Pages/CancelHajjReg/PostCancelHajjReg";
import CancalPreRegAll from "../../modules/Hajji Management/Pages/CancelPreReg/CancelPreRegAll";
import PostCancelPreReg from "../../modules/Hajji Management/Pages/CancelPreReg/PostCancelPreReg";
import AllClientToClient from "../../modules/Hajji Management/Pages/ClientToClient/AllClientToClient";
import EditClienttoClient from "../../modules/Hajji Management/Pages/ClientToClient/EditClienttoClient";
import Client2ClientTransfer from "../../modules/Hajji Management/Pages/ClientToClient/PostClientToClient";
import AddGroupToGroup from "../../modules/Hajji Management/Pages/GroupToGroup/AddGroupToGroup";
import EditGrouptoGroup from "../../modules/Hajji Management/Pages/GroupToGroup/EditGrouptoGroup";
import ListGroupToGroup from "../../modules/Hajji Management/Pages/GroupToGroup/ListGroupToGroup";
import TransferIn from "../../modules/Hajji Management/Pages/TransferIn/TransferIn";
import TransferIn_add from "../../modules/Hajji Management/Pages/TransferIn/TransferIn_add";
import TransferOut from "../../modules/Hajji Management/Pages/TransferOut/TransferOut";
import TransferOut_Add from "../../modules/Hajji Management/Pages/TransferOut/TransferOut_Add";
import NewInvoiceOther from "../../modules/Invoice(Other)/Pages/AddInvoiceOther";
import EditInvoice_Other from "../../modules/Invoice(Other)/Pages/EditInvoice_Other";
import ListOfInvoiceOther from "../../modules/Invoice(Other)/Pages/ViewInvoiceInfo/ListOfInvoiceOther";
import ViewInvoiceOther from "../../modules/Invoice(Other)/Pages/ViewInvoiceInfo/ViewInvoiceOther";
import EditInvoice_Visa from "../../modules/Invoice(Visa)/Pages/EditInvoice_Visa";
import NewInvoiceVisa from "../../modules/Invoice(Visa)/Pages/NewInvoiceVisa";
import InvoiceVisaLists from "../../modules/Invoice(Visa)/Pages/ViewInvoiceInfo/InvoiceVisaLists";
import InvoiceVisaView from "../../modules/Invoice(Visa)/Pages/ViewInvoiceInfo/ViewInvoiceVisa";
import EditInvoiceUmrah from "../../modules/InvoiceUmrah/Pages/EditInvoiceUmrah";
import NewInvoiceAddUmrah from "../../modules/InvoiceUmrah/Pages/NewInvoiceAddUmrah";
import InvoiceUmmrahLists from "../../modules/InvoiceUmrah/Pages/ViewInvoiceInfo/InvoiceUmmrahLists";
import InvoiceUmmrahView from "../../modules/InvoiceUmrah/Pages/ViewInvoiceInfo/ViewInvoiceUmmrah";
import { invoiceAirTicketRoutes } from "../../modules/Invoice_Air_Ticket/routes/invoiceAirticket.routes";
import EditInvoice_PreReg from "../../modules/Invoice_Hajj_Pre_Reg/Pages/EditInvoice_PreReg";
import NewInvoicePreReg from "../../modules/Invoice_Hajj_Pre_Reg/Pages/NewInvoicePreReg";
import HajjPreRegLists from "../../modules/Invoice_Hajj_Pre_Reg/Pages/ViewInvoiceInfo/HajjPreRegLists";
import HajjPreRegView from "../../modules/Invoice_Hajj_Pre_Reg/Pages/ViewInvoiceInfo/ViewInvoiceHajjPreReg";
import EditInvoice_NonComission from "../../modules/Invoice_Non_Comission/pages/EditInvoice_NonComission";
import NewInvoiceNonComission from "../../modules/Invoice_Non_Comission/pages/NewInvoiceNonComission";
import ListOfInvoiceNonCom from "../../modules/Invoice_Non_Comission/pages/ViewInvoiceInfo/ListOfInvoiceNonCom";
import ViewInvoiceNonCom from "../../modules/Invoice_Non_Comission/pages/ViewInvoiceInfo/ViewInvoiceNonCom";
import AddReissueNewClient from "../../modules/Invoice_Re_Issue/pages/AddReissueNewClient";
import EditExistingReissue from "../../modules/Invoice_Re_Issue/pages/EditExistingReissue";
import Edit_Invoice_Re_Issue from "../../modules/Invoice_Re_Issue/pages/Edit_Invoice_Re_Issue";
import ExistingInvoiceReissue from "../../modules/Invoice_Re_Issue/pages/ExistingInvoiceReissue";
import ListOfInvoiceReissue from "../../modules/Invoice_Re_Issue/pages/ViewInvoiceInfo/ListOfInvoiceReissue";
import ViewInvoiceReissue from "../../modules/Invoice_Re_Issue/pages/ViewInvoiceInfo/ViewInvoiceReissue";
import AllInvoiceTours from "../../modules/Invoice_tour/Pages/AllInvoiceTours";
import EditInvoiceTour from "../../modules/Invoice_tour/Pages/EditInvoiceTour";
import NewInvoiceTourPackage from "../../modules/Invoice_tour/Pages/NewInvoiceTourPackage";
import ViewInvocieTour from "../../modules/Invoice_tour/Pages/ViewInvoiceTour";
import VisaProcessing from "../../modules/visa_processing/pages/VisaProcessing";
import { useAppSelector } from "../hooks";
import AddInvoiceOtherPackage from "../../modules/Invoice(Other_Package)/Pages/AddInvoiceOtherPackage";
import ViewInvoiceOtherPackage from "../../modules/Invoice(Other_Package)/Pages/ViewInvoiceInfo/ViewInvoiceOtherPackage";
import ListOfInvoiceOtherPackageAirTicket from "../../modules/Invoice(Other_Package)/Pages/ViewInvoiceInfo/ListOfInvoiceOtherPackage";
import EditInvoice_Other_Package from "../../modules/Invoice(Other_Package)/Pages/EditInvoice_OtherPackage";

export const InvoicesRoutes = (
  permission: rolePermissionType | undefined,
  hasPermission: (module: moduleType) => boolean | undefined
) => {
  const appConfig = useAppSelector((state) => state.configSlice);
  const addMRPermission = permission?.money_receipt?.["create:any"];

  return (
    <>
      {/* ======================== INVOICES ==================== */}

      {appConfig && appConfig?.tac_airticket_type === "IATA"
        ? invoiceAirTicketRoutes(permission, hasPermission)
        : invoiceAirTicketRoutes(permission, hasPermission)}

      {/* AIR TICKET NON COMMISSION */}
      <>
        <Route
          path="/noncomairticket"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_non_commission") &&
                getListPermission(permission?.invoice_non_commission)
              }
              element={
                <ListOfInvoiceNonCom
                  permission={permission?.invoice_non_commission}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />
        <Route
          path="/noncomairticket/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_non_commission") &&
                permission?.invoice_non_commission?.[crud.read]
              }
              element={
                <ViewInvoiceNonCom
                  permission={permission?.invoice_non_commission}
                />
              }
            />
          }
        />

        <Route
          path="/noncomairticket/add"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_non_commission") &&
                permission?.invoice_non_commission?.[crud.create]
              }
              element={<NewInvoiceNonComission />}
            />
          }
        />
        <Route
          path="/noncomairticket/edit/:nonComissionEditId"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_non_commission") &&
                permission?.invoice_non_commission?.["update:any"]
              }
              element={<EditInvoice_NonComission />}
            />
          }
        />
      </>

      {/* REISSUE AIRTICKET */}
      <>
        <Route
          path="/reissueairticket/newclient"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_reissue") &&
                permission?.invoice_reissue?.[crud.create]
              }
              element={<AddReissueNewClient />}
            />
          }
        />
        <Route
          path="/reissueairticket/existing"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_reissue") &&
                permission?.invoice_reissue?.[crud.create]
              }
              element={<ExistingInvoiceReissue />}
            />
          }
        />
        <Route
          path="/reissueairticket"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_reissue") &&
                getListPermission(permission?.invoice_reissue)
              }
              element={
                <ListOfInvoiceReissue
                  permission={permission?.invoice_reissue}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />
        <Route
          path="/reissueairticket/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_reissue") &&
                permission?.invoice_reissue?.[crud.read]
              }
              element={
                <ViewInvoiceReissue permission={permission?.invoice_reissue} />
              }
            />
          }
        />

        <Route
          path="/reissueairticket/edit/:reIssueEditId"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_reissue") &&
                permission?.invoice_reissue?.["update:any"]
              }
              element={<Edit_Invoice_Re_Issue />}
            />
          }
        />
        <Route
          path="/reissueairticket/edit/existing/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_reissue") &&
                permission?.invoice_reissue?.["update:any"]
              }
              element={<EditExistingReissue />}
            />
          }
        />
      </>
      {/* INVOICE OTHER */}
      <>
        <Route
          path="/invoiceother/add"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_other") &&
                permission?.invoice_other?.[crud.create]
              }
              element={<NewInvoiceOther />}
            />
          }
        />

        <Route
          path="/invoiceother/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_other") &&
                permission?.invoice_other?.[crud.read]
              }
              element={
                <ViewInvoiceOther permission={permission?.invoice_other} />
              }
            />
          }
        />
        <Route
          path="/invoiceother"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_other") &&
                getListPermission(permission?.invoice_other)
              }
              element={
                <ListOfInvoiceOther
                  permission={permission?.invoice_other}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />

        <Route
          path="/invoiceother/edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_other") &&
                permission?.invoice_other?.[crud.update]
              }
              element={<EditInvoice_Other />}
            />
          }
        />
      </>
      {/* INVOICE OTHER PACKAGE */}
      <>
        <Route
          path="/invoice-other-package/add"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_other_package") &&
                permission?.invoice_other_package?.[crud.create]
              }
              element={<AddInvoiceOtherPackage />}
            />
          }
        />

        <Route
          path="/invoice-other-package/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_other_package") &&
                permission?.invoice_other_package?.[crud.read]
              }
              element={
                <ViewInvoiceOtherPackage
                  permission={permission?.invoice_other_package}
                />
              }
            />
          }
        />
        <Route
          path="/invoice-other-package"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_other_package") &&
                getListPermission(permission?.invoice_other_package)
              }
              element={
                <ListOfInvoiceOtherPackageAirTicket
                  permission={permission?.invoice_other_package}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />

        <Route
          path="/invoice-other-package/edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_other_package") &&
                permission?.invoice_other_package?.[crud.update]
              }
              element={<EditInvoice_Other_Package />}
            />
          }
        />
      </>
      {/* INVOICE VISA */}
      <>
        <Route
          path="/visa/add"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_visa") &&
                permission?.invoice_visa?.[crud.create]
              }
              element={<NewInvoiceVisa />}
            />
          }
        />
        <Route
          path="/visa"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_visa") &&
                getListPermission(permission?.invoice_visa)
              }
              element={
                <InvoiceVisaLists
                  permission={permission?.invoice_visa}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />
        <Route
          path="/visa/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_visa") &&
                permission?.invoice_visa?.[crud.read]
              }
              element={
                <InvoiceVisaView permission={permission?.invoice_visa} />
              }
            />
          }
        />
        <Route
          path="/visa/edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_visa") &&
                permission?.invoice_visa?.[crud.update]
              }
              element={<EditInvoice_Visa />}
            />
          }
        />
      </>

      {/* VISA PROCESSING */}
      <Route
        path="/visa-process"
        element={
          <PermissionRoute permission={["*"]} element={<VisaProcessing />} />
        }
      />

      {/* NEW INVOICE TOUR */}
      <>
        <Route
          path="/tour/add"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_tour_package") &&
                permission?.invoice_tour_package?.[crud.create]
              }
              element={<NewInvoiceTourPackage />}
            />
          }
        />
        <Route
          path="/invoiceTour/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_tour_package") &&
                permission?.invoice_tour_package?.[crud.read]
              }
              element={
                <ViewInvocieTour
                  permission={permission?.invoice_tour_package}
                />
              }
            />
          }
        />
        <Route
          path="/tour/invoice/edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_tour_package") &&
                permission?.invoice_tour_package?.[crud.update]
              }
              element={<EditInvoiceTour />}
            />
          }
        />

        <Route
          path="/tour-invoice/all"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_tour_package") &&
                getListPermission(permission?.invoice_tour_package)
              }
              element={
                <AllInvoiceTours
                  addMRPermission={addMRPermission}
                  permission={permission?.invoice_tour_package}
                />
              }
            />
          }
        />
      </>

      {/* INVOICE HAJJ PRE REGISTRATION */}
      <>
        <Route
          path="/hajjpreregistration/add"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_hajj_pre_reg") &&
                permission?.invoice_hajj_pre_reg?.[crud.create]
              }
              element={<NewInvoicePreReg />}
            />
          }
        />

        <Route
          path="/hajjpreregistration/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_hajj_pre_reg") &&
                permission?.invoice_hajj_pre_reg?.[crud.read]
              }
              element={
                <HajjPreRegView permission={permission?.invoice_hajj_pre_reg} />
              }
            />
          }
        />

        <Route
          path="/hajjpreregistration"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_hajj_pre_reg") &&
                getListPermission(permission?.invoice_hajj_pre_reg)
              }
              element={
                <HajjPreRegLists
                  permission={permission?.invoice_hajj_pre_reg}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />

        <Route
          path="/hajjpreregistration/edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_hajj_pre_reg") &&
                permission?.invoice_hajj_pre_reg?.[crud.update]
              }
              element={<EditInvoice_PreReg />}
            />
          }
        />
      </>

      {/* INVOICE HAJJ REGISTRATION */}

      <Route
        path="/hajj-reg"
        element={
          <PermissionRoute
            permission={
              hasPermission("invoice_hajj_pre_reg") &&
              permission?.invoice_hajj_pre_reg?.[crud.create]
            }
            element={
              <Hajj_Reg
                permission={permission?.invoice_hajj_pre_reg}
                addMRPermission={addMRPermission}
              />
            }
          />
        }
      />
      {/* HAJJI MANAGEMENT */}
      <>
        <Route
          path="/haji/transfer/client_to_client"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_client_to_client?.[crud.create]
              }
              element={<Client2ClientTransfer />}
            />
          }
        />
        <Route
          path="/haji/transfer/client_to_client_edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_client_to_client?.[crud.update]
              }
              element={<EditClienttoClient />}
            />
          }
        />
        <Route
          path="/haji/transfer/client_list"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                getListPermission(permission?.hajji_management_client_to_client)
              }
              element={
                <AllClientToClient
                  permission={permission?.hajji_management_client_to_client}
                />
              }
            />
          }
        />

        <Route
          path="/haji/transfer/group_to_group_edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_group_to_group?.[crud.update]
              }
              element={<EditGrouptoGroup />}
            />
          }
        />

        <Route
          path="/haji/transfer/group_list"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                getListPermission(permission?.hajji_management_group_to_group)
              }
              element={
                <ListGroupToGroup
                  permission={permission?.hajji_management_group_to_group}
                />
              }
            />
          }
        />
        <Route
          path="/haji/transfer/group_to_group"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_group_to_group?.[crud.create]
              }
              element={<AddGroupToGroup />}
            />
          }
        />
        <Route
          path="/haji/transfer/in"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                getListPermission(permission?.hajji_management_client_to_client)
              }
              element={
                <TransferIn
                  openFrom="TRANSFER_IN"
                  permission={permission?.hajji_management_transfer_inout}
                />
              }
            />
          }
        />
        <Route
          path="/haji/transfer/in_add"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_transfer_inout?.[crud.create]
              }
              element={
                <TransferIn_add reason="ADD_NEW" openFrom="TRANSFER_IN" />
              }
            />
          }
        />
        <Route
          path="/haji/transfer/in_edit/:transferInId"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_transfer_inout?.[crud.update]
              }
              element={<TransferIn_add reason="EDIT" openFrom="TRANSFER_IN" />}
            />
          }
        />
        <Route
          path="/haji/transfer/out_list"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                getListPermission(permission?.hajji_management_transfer_inout)
              }
              element={
                <TransferOut
                  openFrom="TRANSFER_OUT"
                  permission={permission?.hajji_management_transfer_inout}
                />
              }
            />
          }
        />
        <Route
          path="/haji/transfer/out_add"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_transfer_inout?.[crud.create]
              }
              element={
                <TransferOut_Add reason="ADD_NEW" openFrom={"TRANSFER_OUT"} />
              }
            />
          }
        />
        <Route
          path="/haji/transfer/Out_edit/:transferInId"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_transfer_inout?.[crud.update]
              }
              element={
                <TransferOut_Add reason="EDIT" openFrom="TRANSFER_OUT" />
              }
            />
          }
        />

        <Route
          path="/haji/transfer/pre_registration_cancel_list"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                getListPermission(permission?.hajji_management_cancel_pre_reg)
              }
              element={
                <CancalPreRegAll
                  permission={permission?.hajji_management_cancel_pre_reg}
                />
              }
            />
          }
        />
        <Route
          path="/haji/transfer/cancel_pre_registration"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_cancel_pre_reg?.[crud.create]
              }
              element={<PostCancelPreReg />}
            />
          }
        />

        <Route
          path="/haji/transfer/hajj_registration_cancel_list"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                getListPermission(permission?.hajji_management_cancel_pre_reg)
              }
              element={
                <CancelHajjRegAll
                  permission={permission?.hajji_management_cancel_pre_reg}
                />
              }
            />
          }
        />

        <Route
          path="/haji/transfer/cancel_hajj_registration"
          element={
            <PermissionRoute
              permission={
                hasPermission("hajji_management") &&
                permission?.hajji_management_cancel_pre_reg?.[crud.create]
              }
              element={<PostCancelHajjReg />}
            />
          }
        />
      </>

      {/* INVOICE HAJJ */}
      <>
        <Route
          path="/invoicehajj/add"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_hajj") &&
                permission?.invoice_hajj?.[crud.create]
              }
              element={<NewInvoiceAddHajj />}
            />
          }
        />
        <Route
          path="/invoicehajj/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_hajj") &&
                permission?.invoice_hajj?.["read:any"]
              }
              element={
                <ViewInvoiceHajj permission={permission?.invoice_hajj} />
              }
            />
          }
        />
        <Route
          path="/invoicehajj"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_hajj") &&
                getListPermission(permission?.invoice_hajj)
              }
              element={
                <InvoiceHajjLists
                  permission={permission?.invoice_hajj}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />

        <Route
          path="/invoicehajj/edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_hajj") &&
                permission?.invoice_hajj?.[crud.update]
              }
              element={<EditInvoiceHajj />}
            />
          }
        />
      </>

      {/* INVOICE UMMRAH */}
      <>
        <Route
          path="/invoiceumrah/add"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_ummrah") &&
                permission?.invoice_ummrah?.[crud.create]
              }
              element={<NewInvoiceAddUmrah />}
            />
          }
        />

        <Route
          path="/invoiceumrah"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_ummrah") &&
                getListPermission(permission?.invoice_ummrah)
              }
              element={
                <InvoiceUmmrahLists
                  permission={permission?.invoice_ummrah}
                  addMRPermission={addMRPermission}
                />
              }
            />
          }
        />
        <Route
          path="/invoiceumrah/details/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_ummrah") &&
                permission?.invoice_ummrah?.[crud.read]
              }
              element={
                <InvoiceUmmrahView permission={permission?.invoice_ummrah} />
              }
            />
          }
        />

        <Route
          path="/invoiceumrah/edit/:id"
          element={
            <PermissionRoute
              permission={
                hasPermission("invoice_ummrah") &&
                permission?.invoice_ummrah?.[crud.update]
              }
              element={<EditInvoiceUmrah />}
            />
          }
        />
      </>
    </>
  );
};
