import { RoleResources } from "../types/role.enums";
import { IRole } from "../types/user.types";

interface IType {
  "update:any"?: string[];
  "create:any"?: string[];
  "read:any"?: string[];
  "delete:any"?: string[];
}

export interface ITableData {
  key: number;
  permissionName: string;
  update: boolean;
  create: boolean;
  read: boolean;
  delete: boolean;
}

export const convertRoleViewData = (data: IRole) => {
  const jsonData = JSON.parse(data.role_permissions);

  const roleData = jsonData[data.role_name];

  const tableData: ITableData[] = [];

  let tableKey = 1;

  if (roleData) {
    for (const key in roleData) {
      if (Object.prototype.hasOwnProperty.call(roleData, key)) {
        const element = roleData[key] as IType;

        const convertedElement = {
          key: tableKey,
          permissionName: getTitleByKey(key),
          update: element["update:any"]?.includes("*") ?? false,
          create: element["create:any"]?.includes("*") ?? false,
          read: element["read:any"]?.includes("*") ?? false,
          delete: element["delete:any"]?.includes("*") ?? false,
        };

        tableData.push(convertedElement);

        tableKey++;
      }
    }
  }

  return tableData;
};

// title by key
const TitleAndKeyValue = [
  {
    title: "Invoice (Air Ticket)",
    key: RoleResources.invoice_airticket,
  },

  {
    title: "Invoice (Non commission)",
    key: RoleResources.invoice_non_commission,
  },
  {
    title: "Re Issue - Air Ticket",
    key: RoleResources.invoice_reissue,
  },
  {
    title: "Invoice (Other)",
    key: RoleResources.invoice_other,
  },
  {
    title: "Invoice (Other Package)",
    key: RoleResources.invoice_other_package,
  },
  {
    title: "Invoice (Visa)",
    key: RoleResources.invoice_visa,
  },
  {
    title: "Tour package",
    key: RoleResources.invoice_tour_package,
  },
  {
    title: "Invoice hajj pre registration",
    key: RoleResources.invoice_hajj_pre_reg,
  },
  {
    title: "Invoice hajj ",
    key: RoleResources.invoice_hajj,
  },
  {
    title: "Invoice ummrah",
    key: RoleResources.invoice_ummrah,
  },
  {
    title: "Hajji management module",
    key: "hajjimanagement668@",
  },

  {
    title: "Hajji management",
    key: RoleResources.hajji_management,
  },
  {
    title: "Client to client trnsfr",
    key: RoleResources.hajji_management_client_to_client,
  },
  {
    title: "Group to group trnsfr",
    key: RoleResources.hajji_management_group_to_group,
  },
  {
    title: "Transfer in/out",
    key: RoleResources.hajji_management_transfer_inout,
  },
  {
    title: "Cancel pre registration",
    key: RoleResources.hajji_management_cancel_pre_reg,
  },
  {
    title: "Refund module",
    key: "refund668@",
  },
  {
    title: "Refund",
    key: RoleResources.refund_module,
  },
  {
    title: "Airticket refund",
    key: RoleResources.refund_airticket,
  },
  {
    title: "Other refund",
    key: RoleResources.refund_other_invoice,
  },
  {
    title: "Tour package refund",
    key: RoleResources.refund_tour_package,
  },
  {
    title: "Money receipt module",
    key: "moneyreceipt668@",
  },
  {
    title: "Money receipt",
    key: RoleResources.money_receipt,
  },
  {
    title: "Money receipt advance return",
    key: RoleResources.money_receipt_advr,
  },

  {
    title: "Accounts module",
    key: "account668@",
  },

  {
    title: "Accounts",
    key: RoleResources.accounts_module,
  },
  {
    title: "Account opening balance",
    key: RoleResources.account_opening_balance,
  },

  {
    title: "Account balance transfer",
    key: RoleResources.account_balance_transfer,
  },
  {
    title: "Account non invoice income",
    key: RoleResources.account_non_invoice_income,
  },
  {
    title: "Account investments",
    key: RoleResources.account_investments,
  },
  {
    title: "Account bill adjustment",
    key: RoleResources.account_bill_adjustment,
  },

  {
    title: "Cheque management",
    key: RoleResources.cheque_management,
  },
  {
    title: "Payroll",
    key: RoleResources.payroll,
  },
  {
    title: "Expense",
    key: RoleResources.expense,
  },

  {
    title: "Loan management module",
    key: "loanmsgt668@",
  },

  {
    title: "Loan management",
    key: RoleResources.loan_management_module,
  },
  {
    title: "Loan management authority",
    key: RoleResources.loan_management_authority,
  },
  {
    title: "Loan management loan",
    key: RoleResources.loan_management_loan,
  },
  {
    title: "Loan management payment",
    key: RoleResources.loan_management_payment,
  },
  {
    title: "Loan management receive",
    key: RoleResources.loan_management_receive,
  },

  {
    title: "SMS system",
    key: RoleResources.sms_system,
  },
  {
    title: "Clients",
    key: RoleResources.clients,
  },
  {
    title: "Combined clients",
    key: RoleResources.combined_clients,
  },
  // vendors
  {
    title: "Vendors module",
    key: "vendor668@",
  },

  {
    title: "Vendors",
    key: RoleResources.vendors,
  },
  {
    title: "Vendors payment",
    key: RoleResources.vendors_payment,
  },
  {
    title: "Vendors advr",
    key: RoleResources.vendor_advr,
  },

  {
    title: "Agents",
    key: RoleResources.agents,
  },
  {
    title: "Quotation",
    key: RoleResources.quotation,
  },
  {
    title: "Passport management",
    key: RoleResources.passport_management,
  },

  // report module
  {
    title: "Report module",
    key: "reports668",
  },

  {
    title: "Reports",
    key: RoleResources.report_module,
  },
  {
    title: "Report ledgers",
    key: RoleResources.report_ledgers,
  },
  {
    title: "Report total due advance",
    key: RoleResources.report_total_due_advance,
  },
  {
    title: "Sales report",
    key: RoleResources.sales_report,
  },
  {
    title: "Profit loss report",
    key: RoleResources.profit_loss_report,
  },
  {
    title: "Expense report",
    key: RoleResources.expense_report,
  },
  {
    title: "Passport report",
    key: RoleResources.passport_report,
  },
  {
    title: "Passenger list report",
    key: RoleResources.passenger_list_report,
  },
  {
    title: "Vendor wise purchase payment",
    key: RoleResources.vendor_wise_purchase_payment,
  },
  {
    title: "Client discount",
    key: RoleResources.client_discount,
  },
  {
    title: "Journey date wise report",
    key: RoleResources.journey_date_wise_report,
  },
  {
    title: "Ait report",
    key: RoleResources.ait_report,
  },
  {
    title: "Accounts report",
    key: RoleResources.accounts_report,
  },
  {
    title: "Refund report",
    key: RoleResources.refund_report,
  },
  {
    title: "Summary",
    key: RoleResources.summary,
  },
  {
    title: "Country wise report",
    key: RoleResources.country_wise_report,
  },
  {
    title: "Trash list",
    key: RoleResources.trash_list,
  },
  {
    title: "User login history",
    key: RoleResources.user_login_history,
  },
  {
    title: "Audit trail",
    key: RoleResources.audit_trail,
  },

  {
    title: "Configuration module",
    key: "configuration668",
  },

  {
    title: "Configurations",
    key: RoleResources.configuration_module,
  },
  {
    title: "Client category",
    key: RoleResources.client_category,
  },
  {
    title: "Airports",
    key: RoleResources.airports,
  },
  {
    title: "Products",
    key: RoleResources.products,
  },
  {
    title: "Visa type",
    key: RoleResources.visa_type,
  },
  {
    title: "Departments",
    key: RoleResources.departments,
  },
  {
    title: "Room types",
    key: RoleResources.room_types,
  },
  {
    title: "Transport types",
    key: RoleResources.transport_types,
  },
  {
    title: "Designations",
    key: RoleResources.designations,
  },
  {
    title: "Employee",
    key: RoleResources.employee,
  },
  {
    title: "Users",
    key: RoleResources.users,
  },
  {
    title: "Users role",
    key: RoleResources.users_role,
  },
  {
    title: "Tour itinerary",
    key: RoleResources.tour_itinerary,
  },
  {
    title: "Passport status",
    key: RoleResources.passport_status,
  },
  {
    title: "Gorups",
    key: RoleResources.groups,
  },
  {
    title: "Maharams",
    key: RoleResources.maharam,
  },
  {
    title: "Agency",
    key: RoleResources.agency,
  },
  {
    title: "Airline",
    key: RoleResources.airline,
  },
  {
    title: "Expense head",
    key: RoleResources.expense_head,
  },
  {
    title: "Companies",
    key: RoleResources.companies,
  },

  {
    title: "Database module",
    key: "database668",
  },

  {
    title: "Database backup",
    key: RoleResources.database_backup,
  },
];

export const getTitleByKey = (param: string) => {
  const result = TitleAndKeyValue.find((item) => item.key === param);
  return result ? result.title : param;
};
