import { Divider, Form } from "antd";
import { FormInstance, useWatch } from "antd/es/form/Form";
import { useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import FormHeaderTitle from "../../Invoice(Visa)/Components/FormHeaderTitle";
import { IBilling_information } from "../../InvoiceUmrah/Types/InvoiceUmrahTypes";
import InvoiceOtherPackageBillingInfoForm from "./Billing_Info_List";

type Props = {
  form: FormInstance<any>;
  billing_information?: IBilling_information[] | any;
  setProductId?: number;
  totalCost?: number;
  conditionalCostVendor?: boolean;
  requireSellingPrice?: boolean;
};
const BillingInformation = ({
  form,
  billing_information,
  setProductId,
  totalCost,
  conditionalCostVendor,
  requireSellingPrice,
}: Props) => {
  const fullList: IBilling_information[] = useWatch(
    ["billing_information"],
    form
  );

  useEffect(() => {
    form.setFieldsValue({
      invoice_sub_total: fullList?.reduce(
        (sum: number, item: any) => sum + (item?.billing_total_sales || 0),
        0
      ),
    });
  }, [fullList]);

  const role_name = useAppSelector((state) => state.user?.role_name);

  const editPermission = useAppSelector((sate) => sate.user?.role_permissions);
  const editPermissionParse = editPermission
    ? JSON.parse(editPermission)
    : undefined;
  const checkPermission =
    editPermissionParse[role_name!]?.invoice_other_billing;

  const disableEdit = checkPermission;

  const disableRemoveButtonIndex = billing_information?.length;

  return (
    <div className="border p-5 my-20">
      <FormHeaderTitle title="Billing Information 💵" />
      <Form.List name="billing_information" initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <>
                <InvoiceOtherPackageBillingInfoForm
                  form={form}
                  field={field}
                  key={index}
                  billing_information={billing_information}
                  setProductId={setProductId}
                  add={add}
                  remove={remove}
                  index={index}
                  totalCost={totalCost}
                  conditionalCostVendor={conditionalCostVendor}
                  requireSellingPrice={requireSellingPrice}
                  disableRemoveButtonIndex={
                    disableEdit && disableRemoveButtonIndex
                  }
                />

                {fields.length > 1 && <Divider />}
              </>
            );
          })
        }
      </Form.List>
    </div>
  );
};

export default BillingInformation;
