import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Checkbox, Col, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import { setModal } from "../../../../../common/slices/commonSlice";
import { IPermission } from "../../../../../common/types/commonTypes";
import {
  TableParams,
  handleTableChange,
} from "../../../../../common/utils/common.utils";
import LoadingIndicator from "../../../../../components/common/spinner/LoadingIndicator";
import { IAccountIncentiveIncome } from "../../../AccountsTypes/AccountsTypes";
import { useLazyGetAllIncentiveIncomeQuery } from "../endpoints/incentiveIncomeEndpoints";
import Incentive_Income_Add_Modal from "../modal/vendor/Incentive_Income_Add_Modal";
import { ListOfIncentiveIncomeColumn } from "../utils/vendor/ListOfIncentiveIncomeColumn";

type Props = {
  tabChanged: string;
  permission?: IPermission;
};

const Vendor_Incentive_Income = ({ permission, tabChanged }: Props) => {
  const [editInfo, setEditInfo] = useState<IAccountIncentiveIncome | null>(
    null
  );

  const [checkButtonState, setCheckButtonState] = useState(false);

  const dispatch = useAppDispatch();

  const showModal = () => {
    dispatch(setModal(true));
  };

  const [fetchIncentive, { isLoading, data, isFetching }] =
    useLazyGetAllIncentiveIncomeQuery({
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const count = cache.data?.count;
        const dataToReturn: IAccountIncentiveIncome[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
          }
        }
        return { ...cache, data: { dataToReturn, count } };
      },
    });

  useEffect(() => {
    fetchIncentive({ query: "?trash=0&page=1&size=20" });
  }, []);
  const handleTrashButton = () => {
    if (checkButtonState == false) {
      fetchIncentive({ query: "?trash=1&page=1&size=20" });
      setCheckButtonState(true);
    } else {
      fetchIncentive({ query: "?trash=0&page=1&size=20" });
      setCheckButtonState(false);
    }
  };
  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: "0", pageSize: 20 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100", "200", "500"],
      pageSize: 20,
      total: data.count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data.count },
    }));
  }, [data.count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    !checkButtonState
      ? fetchIncentive({
          query: `${query}`,
        })
      : fetchIncentive({
          query: `${query}&trash=1`,
        });
  };

  return (
    <div>
      <Space style={{ marginBottom: "1rem" }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }}>
          <Incentive_Income_Add_Modal
            type={tabChanged}
            permission={permission}
          />
        </Row>
      </Space>

      {checkButtonState && (
        <Alert
          style={{ marginTop: "1rem" }}
          message="Viewing Trashed Incentive Income"
          type="error"
        />
      )}

      <Table
        size="small"
        bordered
        columns={ListOfIncentiveIncomeColumn({
          setEditInfo,
          showModal,
          permission,
          checkButtonState,
          queryData,
        })}
        pagination={
          data.count !== undefined && data.count > 20
            ? tableParams.pagination
            : false
        }
        dataSource={data.dataToReturn}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: <LoadingIndicator />,
        }}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              isTrash: checkButtonState,
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
      />
    </div>
  );
};

export default Vendor_Incentive_Income;
