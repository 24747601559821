import { Button, Form, message, Modal, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FormatDateV1 } from "../../../common/utils/common.utils";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";
import BillingInformation from "../Components/BillingInformation";
import HotelInformation from "../Components/HotelInformation";
import TicketInformation from "../Components/TicketInformation";
import TransportInformation from "../Components/TransportInformation";
import {
  IBillingInfo,
  IHotelInfo,
  IInvoiceOtherPackageCosting,
  IOtherInvoiceFormValue,
  ITicketInfo,
  ITransportInfo,
} from "../Type/Invoce.other.interface";
import {
  useLazyGetViewInvoiceOtherPackageQuery,
  usePostInvoiceOtherPackageCostingMutation,
} from "../Api/invoiceOtherPackageEndpoints";
import { IBilling_information } from "../../InvoiceUmrah/Types/InvoiceUmrahTypes";

type Props = { invoiceId: number };
const AddCostingFormModalInvoiceOtherPackage = ({ invoiceId }: Props) => {
  const [form] = Form.useForm();
  const [fetchBillingQuery, { data: billingInfo, isLoading: fetchLoading }] =
    useLazyGetViewInvoiceOtherPackageQuery();

  const [open, setOpen] = useState(false);
  const showModal = () => {
    fetchBillingQuery(invoiceId);
    setOpen(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const totalBillingCost = billingInfo?.data?.billing_information?.reduce(
    (sum: number, item: IBilling_information) =>
      sum + Number(item?.billing_cost_price || 0),
    0
  );

  const calculateTotalCost = (key: string, costField: string) => {
    const data = Form.useWatch(key, form);
    return (
      data?.reduce(
        (sum: number, item: any) => sum + (Number(item?.[costField]) || 0),
        0
      ) || 0
    );
  };
  // Calculate costs
  const totalTicketCost = calculateTotalCost("ticketInfo", "ticket_cost_price");
  const totalHotelCost = calculateTotalCost(
    "hotel_information",
    "hotel_cost_price"
  );
  const totalTransportCost = calculateTotalCost(
    "transport_information",
    "transport_cost_price"
  );
  // Calculate overall cost
  const totalCost =
    totalTicketCost + totalHotelCost + totalTransportCost + totalBillingCost;

  // form submit
  const [addCost, { isError, isSuccess, error, isLoading }] =
    usePostInvoiceOtherPackageCostingMutation();
  const invoiceDetails = billingInfo?.data || {};
  const hotelInfoData = invoiceDetails?.hotel_information?.map(
    (item: IHotelInfo) => {
      return {
        ...item,
        hotel_check_in_date: item.hotel_check_in_date
          ? dayjs(item.hotel_check_in_date)
          : undefined,
        hotel_check_out_date: item.hotel_check_out_date
          ? dayjs(item.hotel_check_out_date)
          : undefined,
      };
    }
  );
  const ticketInfoData = invoiceDetails?.ticketInfo?.map(
    (item: ITicketInfo) => {
      return {
        ...item,
        ticket_journey_date: item.ticket_journey_date
          ? dayjs(item.ticket_journey_date)
          : undefined,
        ticket_return_date: item.ticket_return_date
          ? dayjs(item.ticket_return_date)
          : undefined,
        ticket_route: item?.ticket_route ? item.ticket_route : undefined,
        ticket_route_prev: item?.ticket_route ? item.ticket_route : undefined,
      };
    }
  );
  const transportInfo = invoiceDetails?.transport_information?.map(
    (item: ITransportInfo) => {
      return {
        ...item,
        transport_drop_off_time: item.transport_drop_off_time
          ? dayjs(item.transport_drop_off_time)
          : undefined,
        transport_pickup_time: item.transport_pickup_time
          ? dayjs(item.transport_pickup_time)
          : undefined,
      };
    }
  );

  useEffect(() => {
    form.setFields([
      {
        name: "hotel_information",
        value: hotelInfoData?.length ? hotelInfoData : [{}],
      },
      {
        name: "ticketInfo",
        value: ticketInfoData?.length ? ticketInfoData : [{}],
      },
      {
        name: "transport_information",
        value: transportInfo?.length ? transportInfo : [{}],
      },
      {
        name: "billing_information",
        value: invoiceDetails?.billing_information?.length
          ? invoiceDetails?.billing_information.map((item: IBillingInfo) => ({
              ...item,
              pax_name: item?.billing_pax_name,
              billing_product_id: item?.billing_product_id,
            }))
          : [{}],
      },
    ]);
  }, [invoiceDetails]);

  const onFinish = (values: IOtherInvoiceFormValue) => {
    const billing_information = values?.billing_information?.map(
      (item, index) => {
        return {
          ...item,
          billing_pax_name: item.pax_name,
          billing_id: invoiceDetails?.billing_information[index]?.billing_id,
        };
      }
    );
    const ticketInfos = values?.ticketInfo?.map((item) => {
      return {
        ...item,
        ticket_journey_date: FormatDateV1(item.ticket_journey_date),
        ticket_return_date: FormatDateV1(item.ticket_return_date),
        ticket_route: item?.ticket_route ? item.ticket_route : undefined,
      };
    });
    const hotel_informations = values?.hotel_information?.map((item) => {
      return {
        ...item,
        hotel_check_out_date: FormatDateV1(item.hotel_check_out_date),
        hotel_check_in_date: FormatDateV1(item.hotel_check_in_date),
      };
    });
    const transport_informations = values?.transport_information?.map(
      (item) => {
        return {
          ...item,
          transport_pickup_time:
            item.transport_pickup_time &&
            dayjs(item.transport_pickup_time).format(),
          transport_drop_off_time:
            item.transport_drop_off_time &&
            dayjs(item.transport_drop_off_time).format(),
        };
      }
    );
    const body: any = {
      ticketInfo: ticketInfos,
      hotel_information: hotel_informations,
      transport_information: transport_informations,
      billing_information: billing_information,
    };
    const sanitizeFormData = (data: IInvoiceOtherPackageCosting) => {
      const sanitizedData: any = {};

      for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
          sanitizedData[key] = value
            .map((item) =>
              typeof item === "object" && item !== null
                ? sanitizeFormData(item)
                : item
            )
            .filter((item) => item && Object.keys(item).length > 0);
        } else if (typeof value === "object" && value !== null) {
          const sanitizedObject = sanitizeFormData(value);
          if (Object.keys(sanitizedObject).length > 0) {
            sanitizedData[key] = sanitizedObject;
          }
        } else if (value !== null && value !== undefined && value !== "") {
          sanitizedData[key] = value;
        }
      }

      return sanitizedData;
    };
    const sanitizedData = sanitizeFormData(body);
    addCost({ body: sanitizedData, id: invoiceId });
  };

  useEffect(() => {
    if (isError) {
      message.error("There was an error");
    } else if (isSuccess) {
      message.success("Invoice other package cost has been updated");
      handleCancel();
      form.resetFields();
    }
  }, [isSuccess, isError]);

  return (
    <>
      <Button size="small" type="primary" onClick={showModal}>
        <Typography style={{ color: "#ffffff" }}>Add Costing</Typography>
      </Button>
      <Modal
        width={1220}
        forceRender
        style={{ top: 20 }}
        title="Invoice other package costing"
        open={open}
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
      >
        {isLoading && <LoadingIndicator />}
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => message.error("Submission Failed")}
          form={form}
          initialValues={{
            transport_information: [{ transport_is_deleted: 0 }],
          }}
        >
          <TicketInformation
            form={form}
            ticket_information={invoiceDetails?.ticketInfo!}
          />
          <HotelInformation
            form={form}
            hotel_info={invoiceDetails?.hotel_information}
          />
          <TransportInformation
            form={form}
            trans_info={invoiceDetails?.transport_information}
          />
          <BillingInformation
            totalCost={totalCost}
            form={form}
            conditionalCostVendor
            requireSellingPrice
            billing_information={invoiceDetails?.billing_information}
          />
        </Form>
      </Modal>
    </>
  );
};

export default AddCostingFormModalInvoiceOtherPackage;
