import React, { useEffect } from "react";
import {
  FormInputItem,
  NumberInput,
  TextAreaInput,
} from "../../../components/common/FormItem/FormItems";
import { Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { useWatch } from "antd/es/form/Form";
import { useGetAllAgentProfileQuery } from "../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints";

const SubtotalAndNetTotalForExistingClient = ({
  form,
  isModified,
}: {
  form: FormInstance<any>;
  isModified?: boolean;
}) => {
  const { data } = useGetAllAgentProfileQuery();
  const invoiceAgentId = useWatch("invoice_agent_id", form);
  const invoice_discount = useWatch("invoice_discount", form);
  const invoice_vat = useWatch("invoice_vat", form);
  const invoice_service_charge = useWatch("invoice_service_charge", form);
  const airticket_subtotal = useWatch("invoice_sub_total", form);
  const invoiceTotalProfit = useWatch("invoice_total_profit", form);
  const existingClientBill = useWatch(["existingClient"]);
  if (existingClientBill && existingClientBill.length) {
    const total_Subtotal = existingClientBill.reduce(
      (acc: number, curr: any) => {
        return acc + (curr.airticket_client_charge || 0); // Safely add airticket_profit
      },
      0
    ); // Initialize accumulator as 0
    form.setFieldsValue({
      invoice_sub_total: total_Subtotal,
    });
    const total_Profit = existingClientBill.reduce((acc: number, curr: any) => {
      return acc + (curr.airticket_profit || 0); // Safely add airticket_profit
    }, 0); // Initialize accumulator as 0
    form.setFieldsValue({
      invoice_total_profit: total_Profit,
    });
    const total_Vendor = existingClientBill.reduce((acc: number, curr: any) => {
      return acc + (curr.airticket_vendor_charge || 0); // Safely add airticket_profit
    }, 0); // Initialize accumulator as 0
    form.setFieldsValue({
      invoice_total_vendor_price: total_Vendor,
    });
  }
  // selected agent
  const selectedAgent = data?.data?.find(
    (item) => item.agent_id === invoiceAgentId
  );
  useEffect(() => {
    if (selectedAgent && isModified) {
      form.setFieldsValue({
        invoice_agent_com_amount:
          (Number(invoiceTotalProfit) *
            Number(selectedAgent?.agent_commission_rate)) /
          100,
      });
    }
  }, [isModified, invoiceTotalProfit, selectedAgent, form]);

  const netTotal =
    (Number(airticket_subtotal) || 0) +
    (Number(invoice_service_charge) || 0) +
    (Number(invoice_vat) || 0) -
    (Number(invoice_discount) || 0);

  form.setFieldsValue({
    invoice_net_total: netTotal,
  });

  return (
    <div className="border p-5">
      <Row>
        <FormInputItem
          name={"invoice_sub_total"}
          label="Subtotal :"
          size={24}
          readOnly
        />
        <NumberInput
          name={"invoice_discount"}
          label="Invoice Discount :"
          size={24}
        />
        <NumberInput
          name={"invoice_vat"}
          label="Invoice Vat / Tax:"
          size={24}
        />
        <NumberInput
          name={"invoice_service_charge"}
          label="Service Charge :"
          size={24}
        />
        <FormInputItem
          name={"invoice_net_total"}
          label="NetTotal :"
          size={24}
          readOnly
        />
        <FormInputItem
          //   hidden
          name={"invoice_total_profit"}
          label="Total Profit :"
          size={24}
          readOnly
        />
        <NumberInput
          name={"invoice_agent_com_amount"}
          label="Agent Commission :"
          size={24}
        />
        <FormInputItem
          //   hidden
          name={"invoice_total_vendor_price"}
          label="Total Vendor Price:"
          size={24}
          readOnly
        />
        <TextAreaInput size={24} label="Note" name="invoice_note" />
      </Row>
    </div>
  );
};

export default SubtotalAndNetTotalForExistingClient;
