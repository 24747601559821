import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { Ipassport_information } from "../Type/invoiceVisa.interface";
import FormHeaderTitle from "./FormHeaderTitle";
import PassportInformation from "./PassportInformation";

type Props = {
  passportInfo?: Ipassport_information[];
  form: FormInstance<any>;
  formName?: string;
};

const PassportInfo = ({ passportInfo, form, formName }: Props) => {
  return (
    <div className="border p-5 my-20">
      <FormHeaderTitle title="Passport Information" />

      <Form.List name={formName || "passport_information"} initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <div className="p-5">
            {fields.map(({ key, name, ...restField }, index) => {
              return (
                <PassportInformation
                  form={form}
                  name={name}
                  passportInfo={passportInfo}
                  add={add}
                  remove={remove}
                  key={key}
                  formName={formName}
                />
              );
            })}
          </div>
        )}
      </Form.List>
    </div>
  );
};

export default PassportInfo;
