import { Col, Form, Row, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { selectUser } from "../../../auth/states/userSlice";
import { IMoneyReceiptPost } from "../../../common/types/commonInterfaces";
import { FormatDateV1 } from "../../../common/utils/common.utils";
import { FormButton } from "../../../components/common/FormItem/FormItems";
import InvoiceHeaderForm from "../../../components/common/Invoice/InvoiceHeaderForm";
import InvoiceMoneyReceipt, {
  InvoiceDisPrevRadioButton,
} from "../../../components/common/Invoice/InvoiceMoneyReceipt";
import InvoiceVisaSubTotalForm from "../../../components/common/Invoice/InvoiceSubTotal";
import BreadCrumb from "../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";
import { useCheckCreditLimitMutation } from "../../Client/Client/api/endpoints/clientEndpoints";
import { useGetInvoiceNoQuery } from "../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints";
import PassportInfo from "../../Invoice(Visa)/Components/PassportInfo";
import { usePostInvoiceOtherPackageMutation } from "../Api/invoiceOtherPackageEndpoints";
import HotelInformation from "../Components/HotelInformation";
import TicketInformation from "../Components/TicketInformation";
import TransportInformation from "../Components/TransportInformation";
import {
  IInvoiceOtherFormattedValueType,
  IOtherInvoiceFormValue,
} from "../Type/Invoce.other.interface";
import BillingInformation from "../Components/BillingInformation";

const AddInvoiceOtherPackage = () => {
  const [form] = Form.useForm();
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  const {
    data: getInvoiceNo,
    isLoading,
    isFetching,
  } = useGetInvoiceNoQuery("IOP");

  // ============= set total billing ===============
  useEffect(() => {
    form.setFieldsValue({
      invoice_no: getInvoiceNo?.data,
      invoice_show_prev_due: 0,
      invoice_show_discount: 1,
    });
  }, [isFetching]);

  useEffect(() => {
    form.setFieldsValue({
      invoice_sales_date: dayjs(),
    });
  }, []);
  const [
    postInvoiceOther,
    { isError, isSuccess, isLoading: postLoading, error },
  ] = usePostInvoiceOtherPackageMutation();

  const [checkCreditLimit, { isLoading: limitIsLoading }] =
    useCheckCreditLimitMutation();

  const calculateTotalCost = (key: string, costField: string) => {
    const data = Form.useWatch(key, form);
    return (
      data?.reduce(
        (sum: number, item: any) => sum + (item?.[costField] || 0),
        0
      ) || 0
    );
  };

  // Calculate costs
  const totalTicketCost = calculateTotalCost("ticketInfo", "ticket_cost_price");
  const totalHotelCost = calculateTotalCost(
    "hotel_information",
    "hotel_cost_price"
  );
  const totalTransportCost = calculateTotalCost(
    "transport_information",
    "transport_cost_price"
  );

  // Calculate overall cost
  const totalCost = totalTicketCost + totalHotelCost + totalTransportCost;

  // ============== form submit handle
  const user = useSelector(selectUser);
  const onFinish = async (values: IOtherInvoiceFormValue) => {
    const money_receipt: IMoneyReceiptPost = {
      receipt_total_amount: values.receipt_total_amount,
      receipt_payment_type: values.receipt_payment_type,
      account_id: values.account_id,
      charge_amount: values.charge_amount,
      cheque_bank_name: values.cheque_bank_name,
      cheque_number: values.cheque_number,
      receipt_money_receipt_no: values.receipt_money_receipt_no,
      receipt_note: values.receipt_note,
      trans_no: values.trans_no,
      receipt_payment_date: FormatDateV1(values.receipt_payment_date),
    };

    const billing_information = values?.billing_information?.map((item) => {
      return {
        ...item,
        billing_pax_name: item.pax_name,
      };
    });

    const passport_informations = values?.passport_information?.map((item) => {
      return {
        pass_passport_id: item.passport_id,
      };
    });
    const ticketInfos = values?.ticketInfo?.map((item) => {
      const { is_deleted, ...rest } = item;
      return {
        ...rest,
        ticket_journey_date: FormatDateV1(item.ticket_journey_date),
        ticket_return_date: FormatDateV1(item.ticket_return_date),
        ticket_route: item?.ticket_route ? item.ticket_route : undefined,
      };
    });
    const hotel_informations = values?.hotel_information?.map((item) => {
      const { is_deleted, ...rest } = item;
      return {
        ...rest,
        hotel_check_out_date: FormatDateV1(item.hotel_check_out_date),
        hotel_check_in_date: FormatDateV1(item.hotel_check_in_date),
      };
    });
    const transport_informations = values?.transport_information?.map(
      (item) => {
        const { is_deleted, ...rest } = item;
        return {
          ...rest,
          transport_pickup_time:
            item.transport_pickup_time &&
            dayjs(item.transport_pickup_time).format(),
          transport_drop_off_time:
            item.transport_drop_off_time &&
            dayjs(item.transport_drop_off_time).format(),
        };
      }
    );
    const body: IInvoiceOtherFormattedValueType = {
      ...values,
      invoice_due_date: FormatDateV1(values.invoice_due_date),
      invoice_sales_date: FormatDateV1(values.invoice_sales_date),
      passport_information: passport_informations,
      ticketInfo: ticketInfos,
      hotel_information: hotel_informations,
      transport_information: transport_informations,
      invoice_created_by: user?.user_id as number,
      money_receipt,
    };
    body.billing_information = billing_information;
    const sanitizeFormData = (data: IInvoiceOtherFormattedValueType) => {
      const sanitizedData: any = {};

      for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
          sanitizedData[key] = value
            .map((item) =>
              typeof item === "object" && item !== null
                ? sanitizeFormData(item)
                : item
            )
            .filter((item) => item && Object.keys(item).length > 0);
        } else if (typeof value === "object" && value !== null) {
          const sanitizedObject = sanitizeFormData(value);
          if (Object.keys(sanitizedObject).length > 0) {
            sanitizedData[key] = sanitizedObject;
          }
        } else if (value !== null && value !== undefined && value !== "") {
          sanitizedData[key] = value;
        }
      }

      return sanitizedData;
    };
    const sanitizedData = sanitizeFormData(body);
    checkCreditLimit({
      amount: sanitizedData.invoice_net_total,
      combClient: sanitizedData.invoice_combclient_id,
    }).then(async (res: any) => {
      if (res?.data?.data === 1) {
        await postInvoiceOther(sanitizedData).then((res: any) => {
          if (res?.data?.data?.invoice_id)
            updateAndPrev
              ? navigate(
                  `/invoice-other-package/details/${res.data.data.invoice_id}`,
                  {
                    state: location.pathname,
                  }
                )
              : navigate("/invoice-other-package");
        });
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success("Invoice other package added successfully!");
    }
    if (isError) {
      message.error("There was an error");
    }
  }, [postLoading]);

  const invoice_net_total = useWatch(["invoice_net_total"], form);

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={["Invoice (Other Package)"]} />
      {(postLoading || isLoading) && <LoadingIndicator />}
      <Form
        onFinish={onFinish}
        onFinishFailed={() => message.error("Submit failed!")}
        layout="vertical"
        form={form}
      >
        <InvoiceHeaderForm
          form={form}
          invoice_net_totalFieldName={["invoice_net_total"]}
          invoice_agent_com_amountFieldName={["invoice_agent_com_amount"]}
        />
        <PassportInfo form={form} />
        <TicketInformation form={form} />
        <HotelInformation form={form} />
        <TransportInformation form={form} />
        {/* conditionalCostVendor == When Vendor is selected then required the Cost Price and vice versa */}
        <BillingInformation
          totalCost={totalCost}
          form={form}
          conditionalCostVendor
        />
        <InvoiceVisaSubTotalForm form={form} />

        <InvoiceMoneyReceipt
          form={form}
          netTotal={Number(invoice_net_total || 0)}
        />

        <InvoiceDisPrevRadioButton form={form} style={{ marginTop: -30 }} />

        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (isLoading || limitIsLoading)}
              label="CREATE"
              disabled={postLoading || isLoading || limitIsLoading}
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (isLoading || limitIsLoading)}
              disabled={postLoading || isLoading || limitIsLoading}
              label="CREATE & PREVIEW"
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </ClientsStyle>
  );
};

export default AddInvoiceOtherPackage;

const ClientsStyle = styled.div`
  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
`;
