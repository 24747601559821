import { Tabs } from "antd";
import React from "react";
import { useParams } from "react-router";
import InvoiceNotFound from "../../../../auth/pages/InvoiceNotFound";
import { IAirTicketInfo } from "../../../../common/types/commonInterfaces";
import { Fixed2 } from "../../../../common/utils/common.utils";
import InvoiceActivityLogs from "../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs";
import CommonViewInvoice from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import RefundTaxInfoDetails from "../../../../components/common/Invoice/ViewInvoiceInfo/RefundTaxInfoDetails";
import TabDetailsView from "../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView";
import TabPaymentView from "../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView";
import ViewInvoiceHeader from "../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import InoviceAirticketView from "../../../Experement/InoviceAirticketView";
import AirTicketFlightAndPaxView from "../../Components/AirTicketFlightAndPaxView";
import { useGetViewInvoiceQuery } from "../../api/endpoints/airticketInvoiceEndpoints";
import { IPermission } from "../../../../common/types/commonTypes";

type Props = {
  permission?: IPermission;
  addMRPermission: ["*"] | undefined;
};

const ViewInvoicesDetails = ({ addMRPermission, permission }: Props) => {
  const invoice_id = Fixed2(useParams().id);

  // GET INVOICE AIR TICKET DETAILS
  const {
    data: invoices,
    isFetching,
    isLoading,
    isError,
  } = useGetViewInvoiceQuery(invoice_id);
  const invoiceData = invoices?.data;

  const airTicketInfo = invoiceData?.airticket_information as IAirTicketInfo[];
  const taxInfo = invoiceData?.tax_refund;

  const invoice_category_id = invoiceData?.invoice_category_id;
  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);
  const viewRefundTaxAcivityRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = React.useState<
    "viewInvoice" | "costDetails" | "payments" | "activity" | "refundTax"
  >("viewInvoice");

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case "viewInvoice":
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case "costDetails":
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case "payments":
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case "activity":
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    default:
      selectedPrintableRef = viewRefundTaxAcivityRef;
      break;
  }

  if (isError) return <InvoiceNotFound />;

  const items = [
    {
      key: "1",
      label: "Invoice",
      children: (
        <>
          {invoiceData && (
            <CommonViewInvoice
              invoiceData={invoiceData}
              viewInvoicePrintableRef={viewInvoicePrintableRef}
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
              permission={permission}
              children={
                <AirTicketFlightAndPaxView
                  flights={invoiceData.flights}
                  paxDetails={invoiceData.pax_details}
                  airticketDetails={invoiceData.airticket_information}
                  heightRefs={{
                    header_ref,
                    signiture_ref,
                    subtotal_ref,
                  }}
                />
              }
            />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Details",
      children: (
        <TabDetailsView
          children={
            // <ViewAirticketChildren invoiceDetails={invoiceData} />
            <InoviceAirticketView airTicketInfo={airTicketInfo} />
          }
          invoiceDetails={invoiceData}
          viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
        />
      ),
    },
    {
      key: "3",
      label: "Payments",
      children: (
        <>
          {invoiceData && (
            <TabPaymentView
              invoiceDetails={invoiceData}
              viewInvoicePaymentsRef={viewInvoicePaymentsRef}
            />
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Activity Log",
      children: (
        <InvoiceActivityLogs
          id={invoice_id}
          viewInvoiceAcivityRef={viewInvoiceAcivityRef}
        />
      ),
    },
  ];
  if (taxInfo?.ticket_info?.length) {
    items.push({
      key: "5",
      label: "Refund Tax Details",
      children: (
        <RefundTaxInfoDetails
          id={invoice_id}
          taxInfo={taxInfo}
          viewInvoiceAcivityRef={viewRefundTaxAcivityRef}
        />
      ),
    });
  }
  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />
      {isLoading && <LoadingIndicator />}

      <Tabs
        style={{ marginTop: "15px" }}
        onChange={(e) => {
          switch (Fixed2(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint("viewInvoice");
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint("costDetails");
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint("payments");
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint("activity");
              break;

            case 5:
              setSelectedForPrint && setSelectedForPrint("refundTax");
              break;

            default:
              break;
          }
        }}
        type="card"
        items={items}
      ></Tabs>
    </>
  );
};

export default ViewInvoicesDetails;
